import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query";
import { AuthState, loggedOut } from "./slices/authSlice";
import { RootState } from "./store";
import { message } from "antd";
import { data } from "../utils/HeaderData";
import { meta } from "@typescript-eslint/eslint-plugin";

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const fetchBaseQueryWithAuthentication: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const authState = (api.getState() as { auth: AuthState }).auth;
    if (authState.isAuthenticated) message.error("لطفا مجدد وارد سامانه شوید");

    api.dispatch(loggedOut());
    return result;
  } else if (result.error && result.error.status === 403) {
    message.error(
     " شما به داده های درخواستی دسترسی ندارید با مدیر سیستم ارتباط بگیرید"
    );
    return result;
  } else if (result.error && result.error.status === 404) {
    message.error("یافت نشد");
    return result;
  } else if (result.error && result.error.status === 429) {
    message.error("درخواست تکراری لطفا چند دقیقه دیگر مجددا امتحان کنید !!!");
    return result;
  } else if (result.error) {
    if (JSON.parse(JSON.stringify(result.error)).originalStatus === "429") {
      message.error("درخواست تکراری لطفا چند دقیقه دیگر مجددا امتحان کنید !!!");
      return result;
    } else
    {
      console.log(result);
message.error("خطایی رخ داده");

    return result;
    } 
  }
  var newResult={error:result.error,data:result?.data==null?result.data:result.data['result' as keyof typeof undefined ] ,meta:result.meta}
  return newResult;
};
