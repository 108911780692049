import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/signIn";
import { Main } from "./components/Layout";
import Home from  "./pages/Home";
import Tables from  "./pages/Tables";
import Billing from  "./pages/Billing";
import Whatsapp from  "./pages/Whatsapp";
export default function App() {
  return (
    <>
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/whatsapp" />} />
        <Route path="/" element={<Main />}>
          <Route path="dashboard" element={<Home />} />
          <Route path="tables" element={<Tables />} />
          <Route path="billing" element={<Billing />} />
          <Route path="rtl" element={<Home />} />
          <Route path="whatsapp" element={<Whatsapp />} />
        </Route>
      </Routes>
    </>
  );
}
