import {
    Alert, Avatar, Button, Card, Col, Descriptions, DescriptionsProps, Divider, Flex, Input, InputRef, List, message, Popover
    , Skeleton, Space, Spin, Tag, theme, Tooltip, UploadFile
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
    SearchOutlined,
    PlusOutlined,
    RedoOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Chat } from "../../utils/ChatDto";
import { Socket } from "socket.io-client";
import {
    ChatLabelDto,
    MessageSenderDto, NoteItemType, useDeleteApiServicesAppChatDeleteLabelMutation, useGetApiServicesAppChatGetChatLabelsQuery, useGetApiServicesAppNoteGetNotesQuery,
    usePostApiServicesAppChatAddLabelToChatMutation,
    usePostApiServicesAppMessageCreateMutation,
    usePostApiServicesAppMessagePullMessagesByMessageIdsMutation
} from "../../app/services/productApi";
import { AuthState } from "../../app/slices/authSlice";
import { MessageDto } from "../../utils/MessageDto";
import Message from "./Message";
import MessageInput from "./MessageInput";
import { NotificationInstance } from "antd/es/notification/interface";
import axios from "axios";
import { RcFile } from "antd/es/upload";
interface Props {
    socket: Socket;
    baseUrl: string;
    getRandomColorbyString: (str: string) => string | null;
    auth: AuthState;
    chatId: string;
    api: NotificationInstance;
    accounts: string[]
}

const ChatComponent = ({
    socket,
    accounts,
    baseUrl,
    getRandomColorbyString,
    chatId,
    auth,
    api,

}: Props) => {
    const [senders, setSenders] = useState<MessageSenderDto[]>([]);
    const [getSendersAsync] = usePostApiServicesAppMessagePullMessagesByMessageIdsMutation();
    const { data: notes } = useGetApiServicesAppNoteGetNotesQuery({ itemId: chatId, itemType: NoteItemType.$4, pageNumber: 0, itemCount: 200 }, { skip: !auth.isAuthenticated || chatId == null || chatId === "" });
    const [addLabelChatAsync] = usePostApiServicesAppChatAddLabelToChatMutation();
    const [deleteLabelChatAsync] = useDeleteApiServicesAppChatDeleteLabelMutation();
    const { data: labelData } = useGetApiServicesAppChatGetChatLabelsQuery({ number: chatId }, { skip: chatId == "" });
    const [searchMessageText, setSearchMessageText] = useState<string | null>(null);
    const [createMessage] = usePostApiServicesAppMessageCreateMutation();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);
    const [replyMessage, setReplyMessage] = useState<MessageDto | null>(null);
    const { token } = theme.useToken();
    const [chatsData, setChatData] = useState<MessageDto[]>([]);
    const [number, setNumber] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [session, setSession] = useState<string>("");
    const [profileImage, setProfileImage] = useState<string>();
    const displayTime = (ticksInSecs: number) => {
        const date = new Date(ticksInSecs * 1000);
        return date.toLocaleString();
    };

    const tagInputStyle: React.CSSProperties = {
        width: "100%",
        height: 22,
        marginInlineEnd: 8,
        verticalAlign: 'top',
    };


    useEffect(() => {
        socket.on("req", (res) => {
            if (res.event === "onmessage" || res.event === "onselfmessage") {
                if (localStorage.getItem("chatId") === res.chatId) {

                    let temp = JSON.parse(localStorage.getItem("chatsData") || "[]");
                    if (temp.findIndex((x: MessageDto) => x.id === res.id) === -1)
                        temp.push(res);
                    localStorage.setItem("chatsData", JSON.stringify(temp));
                    setChatData(temp);
                    scrollToBottom("scrollableDiv");


                } else {
                    // if (res.event === "onmessage") {
                    //     let tempChats = JSON.parse(localStorage.getItem("chData") || "[]");
                    //     openNotification(
                    //         res.isGroupMsg ? tempChats.filter((x: Chat) => x.id._serialized === res.from)[0]?.contact?.name + "/" + res.sender?.formattedName : res.sender?.formattedName,
                    //         res.type !== "chat" ? "Show Replied Message..." : res.body,
                    //         true,
                    //         res.chatId,
                    //         res.sender?.formattedName,
                    //         res.contact?.profilePicThumbObj?.eurl,
                    //         res.contact?.id?.user,
                    //     );
                    // }

                }

            } else if (res.event === "onack") {
                if (localStorage.getItem("chatId") === res.id.remote) {
                    let temp = JSON.parse(localStorage.getItem("chatsData") || "[]");

                    temp.map((it: MessageDto) => {
                        if (it.id === res.id._serialized) it.ack = res.ack;
                    });

                    setChatData(temp);
                    localStorage.setItem("chatsData", JSON.stringify(temp));
                    scrollToBottom("scrollableDiv");
                }
            } else {
                console.log("req", res);
            }
        });

    }, []);


    useEffect(() => {
        if (chatId !== "")
            getChatsById(chatId)
    }, [chatId]);

    useEffect(() => {
        if (chatsData.length > 0)
            getSendersAsync({ getMessagesByMessageIdsInput: { messageIds: chatsData.map((it: MessageDto) => it.id) } }).unwrap()
                .then((result) => {
                    setSenders(result.data ?? []);
                })
    }, [chatsData]);

    const sendSeen = (_session: string, _isGroup: boolean, _phone: string) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(_session)}`, accept: "*/*" },
        };
        axios
            .post(
                `${baseUrl}api/${_session}/send-seen`,
                {
                    phone: _phone,
                    isGroup: _isGroup
                },
                config
            )
            .then(async function (response) {


            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });

    }

    const getChatsById = (_chatId: string) => {
        const conversations: Chat[] = [...JSON.parse(localStorage.getItem("chData") || "[]")];
        setNumber(_chatId.split("@")[0]);
        var _item = conversations.filter(z => z.id._serialized === chatId)[0];
        const _chatName = _item.contact?.formattedName;
        // const _profileImage = _item.contact.profilePicThumbObj?.eurl;
        const _number = _item.contact.id.user;
        // setProfileImage(_profileImage);
        localStorage.setItem("chatId", _chatId);
        localStorage.setItem("chatName", _chatName);
        localStorage.setItem("number", _number);
        // localStorage.setItem("profileImage", _profileImage);

        setIsLoading(true);
        setChatData([]);
        localStorage.setItem(
            "chatsData",
            JSON.stringify([])
        );

        for (let index = 0; index < accounts.length; index++) {
            const element = accounts[index];
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(element)}`,
                    accept: "*/*",
                },
            };
            axios
                .get(
                    `${baseUrl}api/${element}/get-messages/${_chatId}?count=100&direction=before`,
                    config
                )
                .then(async function (response) {

                    sendSeen(element, conversations.filter(x => x.id._serialized === _chatId)[0]?.isGroup ?? false, _chatId);
                    let tempChatData = [...JSON.parse(localStorage.getItem("chatsData") || "[]")];
                    for (let chIndex = 0; chIndex < response.data.response.length; chIndex++) {
                        const element: MessageDto = response.data.response[chIndex];

                        if (tempChatData.findIndex(x => x.id === element.id) === -1)
                            tempChatData.push(element);
                    }
                    let lastItem: MessageDto = tempChatData.sort((n1, n2) => n1.t - n2.t)[tempChatData.length - 1];
                    let phone = lastItem.fromMe ? lastItem.from : lastItem.to;

                    if (index === accounts.length - 1) {
                        accounts.forEach(element => {
                            if (localStorage.getItem(`${element}Phone`) === phone) {
                                setSession(element);
                            }
                        });
                    }



                    setChatData(tempChatData);


                    localStorage.setItem(
                        "chatsData",
                        JSON.stringify(tempChatData)
                    );

                    setIsLoading(false);
                    scrollToBottom("scrollableDiv");

                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                })
                .finally(async function () { });
        }

    };

    const descItems: DescriptionsProps['items'] = [
        {
            label: 'Link Person',
            children: 'Mohammad Shakeri',
        },
        {
            label: 'Last Purchase Date',
            children: '2023/02/02 18:00:00',
        },
        {
            label: 'Last Purchase Cost',
            children: '$180.000.00',
        },
        {
            label: 'Number of Purchases',
            children: '18',
        },
        {
            label: 'Total Purchase Amount',
            span: { xl: 2, xxl: 2 },
            children: '$200.000.00',
        },
        {
            label: 'Average Purchase Amount',
            span: { xl: 2, xxl: 2 },
            children: '$600.00',
        },

    ];

    const handleAccountDropDownClick = (e: MenuInfo) => {
        setSession(e.key);
        setNumber(localStorage.getItem(`${e.key}Phone`)?.split("@")[0] ?? "");
    }
    const handleActionDropDownClick = (e: MenuInfo, item: MessageDto) => {
        if (e.key == '1') {
            setTimeout(function () {
                document?.getElementById("textMessage")?.focus();
            }, 0);
            setReplyMessage(item);

        }
        else if (e.key === '2') {

            if (item.fromMe) {
                let temp = JSON.parse(localStorage.getItem("chatsData") || "[]");

                temp.map((it: MessageDto) => {
                    if (it.id === item.id) it.spin = true;
                });

                setChatData(temp);
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(session)}`, accept: "*/*" },
                };
                axios
                    .post(`${baseUrl}api/${session}/delete-message`, {
                        phone: item.from.split("@")[0],
                        isGroup: item.isGroupMsg,
                        messageId: item.id
                    }, config)
                    .then(function (response) {
                        message.success("Message deleted");
                        let temp = JSON.parse(localStorage.getItem("chatsData") || "[]");

                        temp.map((it: MessageDto) => {
                            if (it.id === item.id) {
                                it.spin = false;
                                it.type = 'revoked';
                            }
                        });
                        localStorage.setItem("chatsData", JSON.stringify(temp));
                        setChatData(temp);
                    })
                    .catch(function (error) {
                        let temp = JSON.parse(localStorage.getItem("chatsData") || "[]");

                        temp.map((it: MessageDto) => {
                            if (it.id == item.id)
                                it.spin = false;
                        });

                        setChatData(temp);
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }

        }

    }







    const scrollToBottom = (_id: string) => {
        document?.getElementById(_id)?.scrollTo(0, document?.getElementById(_id)?.scrollHeight ?? 0);
    };


    const playSound = () => {
        const audio = new Audio(require("../../assets/whatsapp_notification.mp3"));
        audio.play();
    };

    const getBase64 = (file: RcFile | undefined) =>
        new Promise((resolve, reject) => {
            if (file === undefined)
                return;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });


    const handleClose = (removedTag: ChatLabelDto) => {
        deleteLabelChatAsync({ id: removedTag.id });
    };
    const sendFileBase64 = async (_file: string | null, _fileList: UploadFile[] | null, _message: string | null) => {
        if (_fileList?.length === 0 && _file == null) return;


        const withCaption = _fileList?.length === 1 || _file != null;

        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(session)}`, accept: "*/*" },
        };

        var tempFiles = _fileList == null ? [] : [..._fileList];
        let tempChats = JSON.parse(localStorage.getItem("chData") || "[]");

        for (let index = 0; index < tempFiles.length; index++) {
            const file = tempFiles[index];
            file.status = "uploading";
            try {
                var res = await axios.post(
                    `${baseUrl}api/${session}/send-file-base64`,
                    {
                        phone: chatId,
                        isGroup: tempChats.filter((x: Chat) => x.id._serialized === chatId)[0]?.isGroup ?? false,
                        isNewsletter: false,
                        filename: file.name,
                        caption: withCaption ? _message : "",
                        base64: await getBase64(file.originFileObj),
                    },

                    config
                );
                setSenders([...senders, { messageId: res.data.response[0].id, userId: auth.userId, username: auth.userName }]);

                createMessage({
                    createMessageDto: {
                        messageId: res.data.response[0].id
                    }
                });


            } catch (err) {
                console.log(err);
            }
        }


        if (_file != null) {
            try {
                res = await axios.post(
                    `${baseUrl}api/${session}/send-file-base64`,
                    {
                        phone: chatId,
                        isGroup: tempChats.filter((x: Chat) => x.id._serialized == chatId)[0]?.isGroup ?? false,
                        isNewsletter: false,
                        filename: 'audio.mp3',
                        caption: withCaption ? _message : "",
                        base64: _file,
                    },

                    config
                );
                setSenders([...senders, { messageId: res.data.response[0].id, userId: auth.userId, username: auth.userName }]);
                createMessage({
                    createMessageDto: {
                        messageId: res.data.response[0].id
                    }
                });



            } catch (err) {
                console.log(err);
            }
        }

        setReplyMessage(null);
    };
    const replyTextMesage = (_message: string) => {
        setIsLoading(false);

        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(session)}`, accept: "*/*" },
        };
        let tempChats = JSON.parse(localStorage.getItem("chData") || "[]");
        axios
            .post(
                `${baseUrl}api/${session}/send-reply`,
                {
                    phone: chatId,
                    isGroup: tempChats.filter((x: Chat) => x.id._serialized === chatId)[0]?.isGroup ?? false,
                    messageId: replyMessage?.id,
                    message: _message,
                },
                config
            )
            .then(function (response) {
                setIsLoading(false);
                setSenders([...senders, { messageId: response.data.response[0].id, userId: auth.userId, username: auth.userName }]);
                setReplyMessage(null);
                createMessage({
                    createMessageDto: {
                        messageId: response.data.response[0].id
                    }
                });



            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };

    const sendVoiceBase64 = (_base64: string) => {
        setIsLoading(false);

        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(session)}`, accept: "*/*" },
        };
        let tempChats = JSON.parse(localStorage.getItem("chData") || "[]");
        axios
            .post(
                `${baseUrl}api/${session}/send-voice-base64`,
                {
                    phone: chatId,
                    isGroup: tempChats.filter((x: Chat) => x.id._serialized === chatId)[0]?.isGroup ?? false,
                    base64Ptt: _base64,
                },
                config
            )
            .then(function (response) {
                setIsLoading(false);
                setSenders([...senders, { messageId: response.data.response[0].id, userId: auth.userId, username: auth.userName }]);
                setReplyMessage(null);
                createMessage({
                    createMessageDto: {
                        messageId: response.data.response[0].id
                    }
                });


            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };


    const sendMesage = async (_message: string, _fileList: UploadFile[]) => {
        if ((_message === "" || _message == null) && _fileList.length === 0) return;

        const withCaption = _fileList.length > 1 || _fileList.length === 0;
        if (_fileList.length > 0) await sendFileBase64(null, _fileList, _message);
        let tempChats = JSON.parse(localStorage.getItem("chData") || "[]");
        if (withCaption) {
            if (!replyMessage) {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem(session)}`, accept: "*/*" },
                };
                axios
                    .post(
                        `${baseUrl}api/${session}/send-message`,
                        {
                            phone: chatId,
                            isGroup: tempChats.filter((x: Chat) => x.id._serialized === chatId)[0]?.isGroup ?? false,
                            isNewsletter: false,
                            message: _message,
                        },
                        config
                    )
                    .then(function (response) {
                        setSenders([...senders, { messageId: response.data.response[0].id, userId: auth.userId, username: auth.userName }]);
                        createMessage({
                            createMessageDto: {
                                messageId: response.data.response[0].id
                            }
                        });
                        setIsLoading(false);
                        setReplyMessage(null);
                    })
                    .catch(function (error) {
                        setIsLoading(false);
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }
            else {
                replyTextMesage(_message);
            }

        }
    };

    const showInput = () => {
        setInputVisible(true);
        setTimeout(function () {
            document?.getElementById("tagInput")?.focus();
        }, 0);

    };

    const openNotification = (
        message: string,
        desc: string,
        pauseOnHover: boolean,
        _chatId: string,
        _chatName: string,
        _eurl: string,
        _number: string,
    ) => {
        const btn = (
            <Space>
                <Button
                    type="link"
                    danger={true}
                    size="small"
                    onClick={() => api.destroy()}
                >
                    Close All
                </Button>
            </Space>
        );
        try {
            playSound();
        } catch (err) {
            console.log(err);
        }

        api.open({
            btn: btn,
            message: message,
            description: desc,
            showProgress: true,
            placement: "topRight",
            pauseOnHover,
        });
    };


    const handleInputConfirm = () => {

        if (!inputValue)
            return;
        if (labelData?.labels?.findIndex(x => x.label === inputValue) !== -1) {
            api.open({
                message: `Label '${inputValue}' Already Exists !!!!`,
                type: "error",
                placement: "top",
                showProgress: true,
                pauseOnHover: true,
            });
            return;
        }

        addLabelChatAsync({ addLabelToChatInput: { label: inputValue, number: chatId } });
        setInputVisible(false);
        setInputValue("");

    };





    const tagPlusStyle: React.CSSProperties = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };

    return (
        <>
            <Col
                span={10}
                style={{
                    paddingLeft: "5px",
                    paddingRight: "5px",
                }}
            >
                <Spin spinning={isLoading}>
                    <div>
                        {" "}
                        <Card
                            bordered={false}
                            extra={[<Popover trigger={"click"} placement={"top"} content={<Input placeholder="Search..." onChange={(e) => setSearchMessageText(e.target.value.toUpperCase())} value={searchMessageText ?? ""} style={{ height: 32, width: 130 }} ></Input>}><Button type="link"><SearchOutlined></SearchOutlined></Button></Popover>,
                            <Tag style={{ userSelect: 'none' }} color={getRandomColorbyString(localStorage.getItem("chatStatus") ?? "") ?? "blue"}>{localStorage.getItem("chatStatus")}</Tag>,
                                // <Button
                                //     loading={isLoading === true}
                                //     type="link"

                                // >

                                //     {`Last Seen : ${lastSeenTime == null || lastSeenTime === false
                                //         ? "hidden"
                                //         : displayTime(lastSeenTime === true ? 0 : parseInt(lastSeenTime))
                                //         }`}
                                // </Button>]

                            ]}
                            title={
                                <Space>
                                    <Avatar
                                        icon={<UserOutlined />}
                                        shape="square"
                                        size={38}
                                    // src={profileImage}
                                    />
                                    <h6 className="font-semibold m-0">{localStorage.getItem("chatName")}</h6>
                                </Space>
                            }
                            className="header-solid h-full card-profile-information"
                            style={{ maxHeight: "100%" }}
                        >
                            <div
                                id="chatBoxDiv"
                                className="hiddenScrollbar"
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 16,
                                    maxHeight: 590,
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    overflowAnchor: "none"
                                }}
                            >

                                <List
                                    key={Date.now.toString()}
                                    itemLayout="vertical"
                                    size="small"
                                    dataSource={searchMessageText == null ?
                                        chatsData.concat(notes?.notes?.map(_it => { return { type: "note", ack: 1, body: _it.text ?? "", chatId: { user: _it.itemId, _serialized: _it.id?.toString() } ?? "", sender: { formattedName: _it.creatorUserName ?? "" }, t: _it.creationTimeTiks ?? null ?? "" } as unknown as MessageDto }) ?? []).sort((n1, n2) => n1.t - n2.t) :
                                        chatsData.concat(notes?.notes?.map(_it => { return { type: "note", ack: 1, body: _it.text ?? "", chatId: { user: _it.itemId, _serialized: _it.id?.toString() } ?? "", sender: { formattedName: _it.creatorUserName ?? "" }, t: _it.creationTimeTiks ?? null ?? "" } as unknown as MessageDto }) ?? []).filter(x => (x.caption?.toUpperCase()?.includes(searchMessageText) || x.body?.toUpperCase()?.includes(searchMessageText))).sort((n1, n2) => n1.t - n2.t)}
                                    split={false}
                                    className="conversations-list"

                                    renderItem={(item) => (
                                        <Message
                                            key={item.id + "MSG"}
                                            accounts={accounts}
                                            phoneNumber={number ?? ""}
                                            displayTime={displayTime} baseUrl={baseUrl} getRandomColorbyString={getRandomColorbyString} handleActionDropDownClick={handleActionDropDownClick}
                                            item={item}
                                            senders={senders} session={session} setChatData={setChatData} ></Message>

                                    )}
                                />
                            </div>
                            {/* <div
                                id="chatBoxDiv"
                               
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 16,
                                    maxHeight: 590,
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                }}
                            >
                               
                                <List
                                    itemLayout="vertical"
                                    size="small"
                                    dataSource={}
                                    split={false}
                                    className="conversations-list"

                                    renderItem={(item) => (
                                       
                                    )}
                                />
                            </div> */}
                            <div>
                                {replyMessage != null && (
                                    <Alert
                                        message={replyMessage.body}
                                        banner
                                        onClose={() => setReplyMessage(null)}
                                        closable
                                    />
                                )}

                                <MessageInput
                                    getRandomColorbyString={getRandomColorbyString}
                                    onSend={sendMesage}
                                    sendVoiceBase64={sendVoiceBase64}
                                    sendFileBase64={sendFileBase64}
                                    session={session}
                                    key={"MessageInput"}
                                    accounts={accounts}
                                    chatId={chatId}
                                    handleAccountDropDownClick={handleAccountDropDownClick}>

                                </MessageInput>



                            </div>
                        </Card>
                    </div>
                </Spin>


            </Col>
            <Col
                span={5}
                style={{
                    paddingRight: "2px",
                    paddingLeft: "2px ",
                }}
            >
                <div>
                    <Card
                        style={{ maxHeight: "100%", minHeight: "710px" }}
                        bordered={false}
                        title={<h6 className="font-semibold m-0">More Information...</h6>}
                        className="header-solid h-full hiddenScrollbar"
                    >


                        <div
                            className="hiddenScrollbar"
                            style={{
                                minHeight: 550,
                                maxHeight: 590,
                                overflow: "auto",
                            }}
                        >
                            <Space direction="vertical">
                                <Flex gap="4px 0" wrap>
                                    {labelData?.labels?.map<React.ReactNode>((tag: any) => {

                                        const isLongTag = (tag?.label ?? "").length > 20;
                                        const tagElem = (
                                            <Tag
                                                closable={auth.userName === "admin"}
                                                color={getRandomColorbyString(tag.label ?? "") ?? "blue"}
                                                bordered={false}
                                                key={tag.label ?? ""}
                                                style={{ userSelect: 'none' }}
                                                onClose={() => handleClose(tag)}
                                            >
                                                <span>
                                                    {isLongTag ? `${tag?.label?.slice(0, 20)}...` : tag.label}
                                                </span>
                                            </Tag>
                                        );
                                        return isLongTag ? (
                                            <Tooltip title={tag.label ?? ""} key={tag.label ?? ""}>
                                                {tagElem}
                                            </Tooltip>
                                        ) : (
                                            tagElem
                                        );
                                    })}
                                    {auth.userName === "admin" && <> {inputVisible ? (
                                        <Input
                                            id="tagInput"
                                            ref={inputRef}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            type="text"
                                            size="small"
                                            style={tagInputStyle}
                                            value={inputValue}
                                            onBlur={handleInputConfirm}
                                            onPressEnter={handleInputConfirm}
                                        />
                                    ) : (
                                        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                                            New Tag
                                        </Tag>
                                    )}</>}
                                </Flex>
                                <Descriptions
                                    bordered
                                    column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                                    items={descItems}
                                />
                            </Space>

                        </div>
                    </Card>
                </div>
            </Col>
        </>);
}

export default ChatComponent;