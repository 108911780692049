import { Avatar, Badge, Card, Col, Input, List, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
    SearchOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Chat } from "../../utils/ChatDto";
import axios from "axios";
import { useGetApiServicesAppChatGetAllQuery, useGetApiServicesAppChatGetEnumsQuery, usePostApiServicesAppChatCreateMutation, usePutApiServicesAppChatUpdateMutation } from "../../app/services/productApi";
import { AuthState } from "../../app/slices/authSlice";

interface Props {
    baseUrl: string;
    getRandomColorbyString: (str: string) => string | null;
    auth: AuthState;
    setChatId: (value: React.SetStateAction<string>) => void;
    chData: Chat[];
}


const Conversation = ({
    baseUrl,
    getRandomColorbyString,
    setChatId,
    auth,
    chData,
}: Props) => {
    const [conversations, setConversations] = useState<Chat[]>([]);
    const [searchChatText, setSearchChatText] = useState<string | null>(null);
    const { data: enums } = useGetApiServicesAppChatGetEnumsQuery(undefined, { skip: !auth.isAuthenticated });
    const { data: chats } = useGetApiServicesAppChatGetAllQuery({ maxResultCount: 10000 }, { skip: !auth.isAuthenticated });
    const [createChatAsync] = usePostApiServicesAppChatCreateMutation();
    const [updateChatAsync] = usePutApiServicesAppChatUpdateMutation();
    useEffect(() => {
        setConversations(chData);
    }, [chData]);


    useEffect(() => {
        localStorage.setItem("updating", "false");
        const interval = setInterval(() => {
            if (localStorage.getItem("updating") !== "true")
                updateChatList();
        }, 3000);
        return () => clearInterval(interval);
    });

    const updateChatList = async () => {



        localStorage.setItem("updating", "true");
        const _accounts = [...JSON.parse(localStorage.getItem("accounts") || "[]")];
        for (let index = 0; index < _accounts.length; index++) {
            const element = _accounts[index];
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem(element)}`, accept: "*/*" },
            };
            await axios
                .post(
                    `${baseUrl}api/${element}/list-chats`,
                    {
                        onlyWithUnreadMessage: true
                    },
                    config
                )
                .then(async function (response) {

                    let tempData: Chat[] = [...JSON.parse(localStorage.getItem("chData") || "[]")];
                    for (let idx = 0; idx < tempData.length; idx++) {
                        if (tempData[idx].session === element)
                            if (response.data.findIndex((x: Chat) => x.id.user === tempData[idx].id.user) === -1)
                                tempData[idx].unreadCount = 0;

                    }
                    for (let index = 0; index < response.data.length; index++) {
                        let elementCh: Chat = response.data[index];
                        elementCh.session = element;
                        const chIndex = tempData.findIndex(x => x.id.user === elementCh.id.user);
                        if (chIndex === -1)
                            tempData.push(elementCh);
                        else
                            tempData[chIndex] = elementCh;
                    }

                    setConversations(tempData);
                    localStorage.setItem("chData", JSON.stringify(tempData));

                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    if (index === _accounts.length - 1)
                        localStorage.setItem("updating", "false");
                });
        }

    }





    const upsertChatStatus = async (_number: string, status: string) => {

        const current = chats?.items?.filter(x => x.number === _number)[0];
        if (current == null) {

            try {
                await createChatAsync({ createChatDto: { number: _number, status: parseInt(status) } }).unwrap();

            }
            catch (error) {

                console.log(error);

            }
        }
        else {
            updateChatAsync({ chatDto: { id: current.id, number: _number, status: parseInt(status) } });
        }
    }


    return (<>
        <Col
            span={5}
            style={{
                paddingRight: "2px",
                paddingLeft: "2px ",
            }}
        >
            <div>
                <Card
                    extra={<Input placeholder="Search..." suffix={<SearchOutlined></SearchOutlined>} onChange={(e) => setSearchChatText(e.target.value.toUpperCase())} value={searchChatText ?? ""} style={{ height: 40, width: 130 }} ></Input>}
                    style={{ maxHeight: "100%", minHeight: "710px" }}
                    bordered={false}
                    title={<h6 className="font-semibold m-0">Conversations</h6>}
                    className="header-solid h-full hiddenScrollbar"
                >

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <div
                            className="hiddenScrollbar"
                            style={{
                                minHeight: "490px",
                                maxHeight: "590px",
                                overflow: "auto",
                            }}
                        >
                            <List
                                size="small"
                                itemLayout="vertical"
                                dataSource={searchChatText == null ? conversations.sort((n1, n2) => n2.t - n1.t) : conversations.filter(x => x.contact.formattedName.toUpperCase().includes(searchChatText)).sort((n1, n2) => n2.t - n1.t)}
                                split={false}
                                className="conversations-list"
                                renderItem={(item) => (
                                    <>


                                        <List.Item
                                            style={{
                                                padding: "0px !important",
                                                cursor: "pointer",
                                                marginBottom: "5px",
                                                borderRadius: "10px",
                                                backgroundColor: `${getRandomColorbyString((localStorage.getItem(item.session + "Phone") ?? "") + "color")}`,
                                            }}

                                        >

                                            <List.Item.Meta
                                                style={{ padding: "0px !important", marginInlineEnd: "0px !important", marginBlockEnd: "0px !important" }}

                                                avatar={
                                                    <Badge showZero={false} count={item.unreadCount <= 0 ? 0 : item.unreadCount} offset={[-2, 11]} >
                                                        <Avatar
                                                            onClick={() => setChatId(item.id._serialized)}
                                                            shape="square"
                                                            size={55}
                                                            icon={<UserOutlined />}
                                                        // src={item.contact.profilePicThumbObj?.eurl}
                                                        />
                                                    </Badge>

                                                }
                                                title={<span onClick={() => setChatId(item.id._serialized)}> {item.contact?.formattedName}</span>}
                                                description={
                                                    <Select
                                                        onChange={(value) => upsertChatStatus(item.id.user, value)}
                                                        defaultValue="0"

                                                        style={{ width: '100%' }}
                                                        variant="borderless"
                                                        options={enums?.statusEnum?.map((x) => { return { value: x.value, label: x.name } }) ?? []}
                                                        value={chats?.items?.filter(x => x.number === item.contact.id.user)[0]?.status.toString()}
                                                    />}
                                            />
                                        </List.Item>
                                    </>

                                )}
                            />


                        </div>
                    </Space>

                </Card>
            </div>
        </Col></>);
}

export default Conversation;