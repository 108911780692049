import {
  combineReducers,
  configureStore,
  MiddlewareAPI,
  Middleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { autoGeneratedApi } from "./autoGeneratedApi";
import { useDispatch } from "react-redux";
import authReducer from "./slices/authSlice";
import mainReducer from "../Redux/features/MainSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { message } from "antd";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.error("We got a rejected action!" + api.toString());
      message.error(action.error.data.message);
    }

    return next(action);
  };

const persistConfig = {
  key: "root",
  version: 1,
  whitelist: ["auth","main"],
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  main:mainReducer,
  [autoGeneratedApi.reducerPath]: autoGeneratedApi.reducer,
}); //maybe delete?

const persistedReducer = persistReducer(persistConfig, rootReducer);



export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(autoGeneratedApi.middleware),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
