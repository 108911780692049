import React, { useEffect } from "react";
import { Layout, Affix } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPlaceMent,
} from "../../Redux/features/MainSlice";
import AntdHeaderRight from "./AntdHeader";
import AntdFooter from "./AntdFooter";
import { selectAuth } from "../../app/slices/authSlice";
import { selectMain } from "../../Redux/features/MainSlice";
const { Header: AntHeader, Content } = Layout;
export default function Main() {
  let { pathname } = useLocation();
  const { navFixed } =
    useSelector(selectMain);
  const dispatch = useDispatch();
  pathname = pathname.replace("/", "");
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/sign-in");
    }
    if (pathname === "rtl") {
      dispatch(setPlaceMent("left"));
    } else {
      dispatch(setPlaceMent("left"));
    }
  }, [pathname, auth]);

  // console.log(pathname);
  // dispatch(openDrawer())
  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      {/* <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => dispatch(handleSidebar(false))}
        visible={openSidebar}
        key={placement === "right" ? "left" : "right"}
        width={150}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={150}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sideNavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sideNavType }}
          >
            <SideNav color={sideNavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        width={150}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sideNavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sideNavType }}
      >
        <SideNav color={sideNavColor} />
      </Sider> */}
      <Layout>
        {navFixed ? (
          <Affix>
            <AntHeader>
              <AntdHeaderRight name={pathname} />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader>
            <AntdHeaderRight name={pathname} />
          </AntHeader>
        )}
        <Content className="content-ant">
          <Outlet />
        </Content>
        <AntdFooter />
      </Layout>
    </Layout>
  );
}
