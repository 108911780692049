import { Button, Form, Input, InputNumber, Select, Space, Upload } from "antd";
import React, { useState } from "react";
import {
    UploadOutlined
} from "@ant-design/icons";
import axios from "axios";

import * as XLSX from 'xlsx';
import { RcFile, UploadFile } from "antd/es/upload";
import { Option } from "antd/es/mentions";
interface Props {
    accounts: string[];
    baseUrl: string;
}

const SendGroupMessage = ({
    baseUrl,
    accounts,
}: Props) => {
    const [sendResult, setSendResult] = useState("");
    const getBase64 = (file: RcFile | undefined) =>
        new Promise((resolve, reject) => {
            if (file === undefined)
                return;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    const sendMessages = async (delay: number, _account: string, _message: string, _file: UploadFile, filename: string | null, _mobiles: string, _names: string,) => {
        localStorage.setItem(
            "mobiles",
            JSON.stringify([])
        );
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(_account)}`, accept: "*/*" },
        };
        let sendIndex = 0;
        var mobiles = _mobiles.split("\n");
        var names = _names.split("\n");
        for (let index = 0; index < mobiles.length; index++) {
            const element = mobiles[index];
            if (element != null && element.length == 12)
                await axios.get(`${baseUrl}api/${_account}/profile-status/${element}`, config)
                    // eslint-disable-next-line no-loop-func
                    .then(function (response) {
                        let tempMobile: { mobile: string, status: string | null }[] = JSON.parse(localStorage.getItem("mobiles") || "[]");
                        tempMobile.push({ mobile: element, status: response.data.response.status });
                        localStorage.setItem(
                            "mobiles",
                            JSON.stringify(tempMobile)
                        );
                        setSendResult(JSON.stringify(tempMobile));
                        if (response.data.response.status != null) {
                            sendIndex = sendIndex + 1;
                            setTimeout(async () => {
                                try {

                                    axios.post(
                                        `${baseUrl}api/${_account}/send-file-base64`,
                                        {
                                            phone: element,
                                            isGroup: false,
                                            isNewsletter: false,
                                            filename: __filename,
                                            caption: _message.replaceAll("[&name&]", names[index]),
                                            base64: await getBase64(_file.originFileObj)
                                        },

                                        config
                                    );

                                } catch (err) {
                                    console.log(err);
                                }

                            }, delay * 1000 * sendIndex);
                        }

                    })



        }



    };


    // const normFile = (e: any) => new Promise((resolve, reject) => {
    //     if (e.file.originFileObj === undefined)
    //         return;
    //     const reader = new FileReader();
    //     reader.readAsDataURL(e.file.originFileObj);
    //     reader.onload = (event) => {


    //         setFile(reader.result?.toString() ?? "");
    //         return e?.fileList;
    //     };


    // });



    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onFinish = (values: any) => {
        sendMessages(values.delay, values.account, values.message, values.file[0], values.file[0].name, values.mobiles, values.names);
    };

    return (<>
        <Form
            layout="inline"

            name="validate_other"
            onFinish={onFinish}

        >
            <Form.Item name="message" rules={[{ required: true, message: 'Please insert message!' }]}>
                <Input.TextArea rows={4} placeholder="Message([&name&] is Name)" />

            </Form.Item>
            <Form.Item
                name="file"


                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please select file!' }]}
            >
                <Upload name="logo" action="/upload.do" listType="picture">
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'Please insert delay period!' }]}>
                <Form.Item name="delay" noStyle>
                    <InputNumber placeholder="seconds" min={10} max={600} />
                </Form.Item>

            </Form.Item>

            <Form.Item name="mobiles" rules={[{ required: true, message: 'Please insert mobiles!' }]}>
                <Input.TextArea rows={4} placeholder="Mobiles" />
            </Form.Item>

            <Form.Item name="names" rules={[{ required: true, message: 'Please insert names!' }]}>
                <Input.TextArea rows={4} placeholder="names" />
            </Form.Item>

            <Form.Item
                name="account"

                rules={[{ required: true, message: 'Please select number!' }]}
            >
                <Select placeholder="select your number">
                    {accounts.map((it) => { return <Option value={it}>{it}</Option> })}
                </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>

                </Space>
            </Form.Item>
        </Form>
        <br></br>
        <Input.TextArea value={sendResult}></Input.TextArea>
    </>);
}

export default SendGroupMessage;