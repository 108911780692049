/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useRef } from "react";

import "animate.css";
import { io } from "socket.io-client";
import {
  IdcardOutlined,
  LogoutOutlined,
  LoginOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  DeleteTwoTone,
  CheckCircleTwoTone,
  ClockCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "../assets/whatsapp_notification.mp3";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  List,
  Avatar,
  Input,
  Spin,
  notification,
  Alert,
  Image,
  UploadFile,

  Badge,
} from "antd";
import { MessageDto } from "../utils/MessageDto";
import { Chat } from "../utils/ChatDto";
import { selectAuth } from "../app/slices/authSlice";
import { useSelector } from "react-redux";
import Conversation from "../components/Whatsapp/Conversation";
import ChatComponent from "../components/Whatsapp/Chat";
import SendGroupMessage from "../components/Whatsapp/SendGroupMessage";
const socket = io("http://5.75.206.216:3001");
export default function Whatsapp() {
  const [session, setSession] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState("");
  const [chatName, setChatName] = useState("");
  const [chatsData, setChatData] = useState<MessageDto[]>([]);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [addAccount, setAddAcounnt] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [lastSeenTime, setLastSeenTime] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [number, setNumber] = useState("");
  const [simColor, setSimColor] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [chData, setData] = useState<Chat[]>([]);
  const [chatStatus, setChatStatus] = useState<string>("تعیین نشده");
  const baseUrl = "http://5.75.206.216:21465/";
  const secretkey = "MHSH";
  const auth = useSelector(selectAuth);


  useEffect(() => {
    if (auth.isAuthenticated) getSessions(undefined);

    socket.connect();
    socket.on("req", (res) => {
      if (res.event === "qrcode") {
        setQrCode(res.qrcode);
      } else if (
        res.event === "status-find"
      ) {
        if (res.status === "qrReadSuccess")
          // setTimeout(() => {
          //   startSession(localStorage.getItem(res.session) ?? "", res.session, true);
          // }, 1000);
          return;
        else if (res.status === "inChat") {
          // setTimeout(() => {
          //   startSession(localStorage.getItem(res.session) ?? "", res.session, true);
          // }, 1000);
        }

      }
    });
    return () => {
      socket.disconnect();
    };
  }, [auth]);





  const startSession = async (token: string, sessionName: string, loading: boolean) => {

    let _accounts = [];
    if (accounts.findIndex(x => x == sessionName) == -1)
      _accounts = [...accounts, sessionName];
    else _accounts = [...accounts];
    localStorage.setItem(
      "chData",
      JSON.stringify([])
    );

    const element = sessionName;
    let _token = localStorage.getItem(element);
    setAddAcounnt(false);
    _token = localStorage.getItem(element) ?? "";


    if (_token == null || _token == "") createSessionForWhatsapp(element);
    else {
      setIsLoading(true);
      const config = {
        headers: { Authorization: `Bearer ${_token}`, accept: "*/*" },
      };
      await axios
        .post(
          `${baseUrl}api/${element}/start-session`,
          {
            webhook: "",
            waitQrCode: false,
          },
          config
        )
        .then(async function (response) {
          setIsLoading(false);
          setShowChatBox(false);
          await getQRCode(_token, element, loading);
        })
        .catch(function (error) {
          setIsLoading(false);
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }


  };



  const logoutSession = (token: string, sessionName: string) => {
    setIsLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${token}`, accept: "*/*" },
    };
    axios
      .post(`${baseUrl}api/${sessionName}/logout-session`, {}, config)
      .then(function (response) {
        setIsLoading(false);
        setShowChatBox(false);
        clearSessionData(sessionName);
        localStorage.removeItem(sessionName);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const getQRCode = async (token: string, sessionName: string, loading: boolean) => {

    setIsLoading(loading);
    const config = {
      headers: { Authorization: `Bearer ${token}`, accept: "*/*" },
    };
    await axios
      .post(
        `${baseUrl}api/${sessionName}/start-session`,
        {
          webhook: "",
          waitQrCode: true,
        },
        config
      )
      .then(async function (response) {
        setIsLoading(false);
        if (
          response.data.status == "CLOSED" ||
          response.data.status == "QRCODE"
        ) {
          setQrCode(response.data.qrcode);
        } else if (response.data.status == "CONNECTED") {
          setQrCode("");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const getSessions = (_session: string | undefined) => {
    setIsLoading(true);
    const config = {
      headers: { Authorization: `Bearer `, accept: "*/*" },
    };
    axios
      .get(`${baseUrl}api/${secretkey}/show-all-sessions`, config)
      .then(function (response) {
        setIsLoading(false);
        var tmpAccounts = [...response.data.response];
        if (_session !== undefined && response.data.response.findIndex((x: string) => x == _session) == -1)
          tmpAccounts.push(_session);
        setAccounts(tmpAccounts);
        localStorage.setItem("accounts", JSON.stringify(tmpAccounts));

        for (let index = 0; index < tmpAccounts.length; index++) {
          const element = tmpAccounts[index];
          if (localStorage.getItem(element) == null || localStorage.getItem(element) === undefined || localStorage.getItem(element) == "") {
            createTokenSession(element);

            createSession(localStorage.getItem(element) ?? "", element);
          }

          if (localStorage.getItem(`${element}Phone`) == null || localStorage.getItem(`${element}Phone`) === undefined || localStorage.getItem(`${element}Phone`) == "")
            getPhone(localStorage.getItem(element) ?? "", element);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const loginS = () => {

    loadMoreChats();
  }

  const checkStatus = (token: string, _session: string | null) => {
    const config = {
      headers: { Authorization: `Bearer ${token}`, accept: "*/*" },
    };
    axios
      .get(`${baseUrl}api/${_session}/status-session`, config)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.status == "CLOSED") startSession(token, session, false);
        else if (response.data.status == "CONNECTED")
          getSessions(undefined);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const createSession = (token: string, _session: string | null) => {
    const config = {
      headers: { Authorization: `Bearer ${token}`, accept: "*/*" },
    };
    axios
      .post(`${baseUrl}api/${secretkey}/start-all`, config)
      .then(function (response) {

        checkStatus(token, _session);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const createToken = (_session: string) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(_session)}`, accept: "*/*" },
    };
    axios
      .post(
        `${baseUrl}api/${_session}/${secretkey}/generate-token`,
        config
      )
      .then(function (response) {
        setSession(_session);
        localStorage.setItem(_session, response.data.token);
        setIsLoading(false);
        getSessions(_session);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

  };

  const createTokenSession = (_session: string) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(_session)}`, accept: "*/*" },
    };
    axios
      .post(
        `${baseUrl}api/${_session}/${secretkey}/generate-token`,
        config
      )
      .then(function (response) {
        setSession(_session);
        localStorage.setItem(_session, response.data.token);

      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

  };
  const createSessionForWhatsapp = (sessionName: string | null) => {
    setIsLoading(true);
    const token = localStorage.getItem(
      sessionName == "" || sessionName == null ? session : sessionName
    );
    if (token == null || token == "" || token === undefined) {
      createToken(sessionName == "" || sessionName == null ? session : sessionName);
    }
    else {
      createSession(token, sessionName == "" || sessionName == null ? session : sessionName);
    }
  };

  const getPhone = (token: string, sessionName: string) => {
    setPhoneNumber("");
    const config = {
      headers: { Authorization: `Bearer ${token}`, accept: "*/*" },
    };
    axios
      .get(
        `${baseUrl}api/${sessionName}/get-phone-number`,

        config
      )
      .then(async function (response) {
        setPhoneNumber(response.data.response);
        localStorage.setItem(`${sessionName}Phone`, response.data.response);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
        createSession(token, sessionName);
      })
      .finally(function () {
        // always executed
      });
  };

  const clearSessionData = (sessionName: string) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(sessionName)}`, accept: "*/*" },
    };
    axios
      .get(
        `${baseUrl}api/${sessionName}/${secretkey}/clear-session-chData`,

        config
      )
      .then(async function (response) {
        getSessions(undefined);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };




  const loadMoreChats = async () => {
    setIsLoading(true);

    for (let index = 0; index < accounts.length; index++) {
      const element = accounts[index];
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(element)}`, accept: "*/*" },
      };
      axios
        .post(
          `${baseUrl}api/${element}/list-chats`,
          {
            count: 400,
            direction: "after"
          },
          config
        )
        .then(async function (response) {
          setIsLoading(false);
          setQrCode("");
          let tempData = [...JSON.parse(localStorage.getItem("chData") || "[]")];
          for (let index = 0; index < response.data.length; index++) {
            let elementCH: Chat = response.data[index];
            const chIndex = tempData.findIndex(x => x.id.user == elementCH.id.user);
            elementCH.session = element;
            if (chIndex == -1)
              tempData.push(elementCH);
            else if (tempData[chIndex].t < elementCH.t)
              tempData[chIndex] = elementCH;
          }
          setData(tempData);
          localStorage.setItem("chData", JSON.stringify(tempData));
          getPhone(localStorage.getItem(element) ?? "", element);
        })
        .catch(function (error) {
          setIsLoading(false);
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }

  };

  const getLastSeen = async (_chatId: string) => {

    var _session = session == null || session == "" ? localStorage.getItem('session') : session;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem(_session ?? "")}`, accept: "*/*" },
    };
    try {
      axios
        .get(
          `${baseUrl}api/${_session}/last-seen/${_chatId.split("@")[0]
          }`,

          config
        )
        .then((result) => {
          setLastSeenTime(result.data.response);

        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const hex2rgb = (_hexaColor: string) => {
    var hex_color = _hexaColor
      , rgb_color = ""
      , pattern_color = "^#([A-Fa-f0-9]{6})$";
    if (hex_color.match(pattern_color)) {
      hex_color = hex_color.replace("#", "")
        ; var r = parseInt(hex_color.substring(0, 2), 16)
        ; var g = parseInt(hex_color.substring(2, 4), 16)
        ; var b = parseInt(hex_color.substring(4, 6), 16);
      return rgb_color = 'rgb(' + r + ' ' + g + ' ' + b + '/ 15%)';
    }
    else {
      return _hexaColor;
    }
  }

  const getRandomColorbyString = (str: string) => {
    if (str == null)
      return null;
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }

    return hex2rgb(colour);
  }

  return (
    <>
      {contextHolder}
      <Spin spinning={isLoading}>
        {auth.userName === "admin" && <Row gutter={[16, 16]}>
          <Col
            span={24}
            style={{
              paddingRight: "0px !important",
              paddingLeft: "0px !important",
            }}
          >
            <Button type="dashed" onClick={() => setShowForm(!showForm)}>
              <ClockCircleOutlined></ClockCircleOutlined> send Group Message
            </Button>

            {showForm && <SendGroupMessage accounts={accounts} baseUrl={baseUrl}></SendGroupMessage>}
            <Button type="dashed" onClick={() => setAddAcounnt(true)}>
              <UserAddOutlined></UserAddOutlined> Add Account{" "}
            </Button>
            {addAccount == true && (
              <>
                <Input
                  value={session}
                  onChange={(e) => setSession(e.target.value)}
                  type="text"
                  placeholder="Account Name..."
                ></Input>
                <Button
                  onClick={() => createSessionForWhatsapp(null)}
                  type="primary"
                  size={"middle"}
                >
                  <UserAddOutlined />
                  Create Session
                </Button>
              </>
            )}{" "}
            {qrCode !== "" && qrCode != null && (
              <Alert
                description={
                  <Image
                    preview={false}
                    width={200}
                    src={
                      qrCode.startsWith("chData:image")
                        ? qrCode
                        : `data:image/png;base64,${qrCode}`
                    }
                  />
                }
                type="success"
              />
            )}
          </Col>
        </Row>}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            span={4}
            style={{
              paddingRight: "2px",
              paddingLeft: "2px ",
            }}
          >
            <div

            >
              <List
                itemLayout="vertical"
                dataSource={accounts}
                renderItem={(item) => (
                  <Row>
                    <Col>
                      <List.Item >

                        {session == item && (<Badge.Ribbon text="Active Line">
                          <Card
                            style={{ backgroundColor: `${getRandomColorbyString(localStorage.getItem(`${item}Phone`) + "color" ?? "")}` }}

                            actions={[
                              <Button
                                onClick={() =>
                                  // startSession(
                                  //   localStorage.getItem(item) ?? "",
                                  //   item,
                                  //   true
                                  // )
                                  loginS()
                                }
                                type="link"
                              >
                                <LoginOutlined />
                                Login
                              </Button>,
                              <Button
                                danger={true}
                                onClick={() =>
                                  logoutSession(localStorage.getItem(item) ?? "", item)
                                }
                                type="link"
                              >
                                <LogoutOutlined />
                                Logout
                              </Button>,
                            ]}
                          >
                            <Card.Meta

                              avatar={
                                <Avatar

                                  shape="square"
                                  size={38}
                                  icon={session == item ? <CheckCircleOutlined /> : <IdcardOutlined />}
                                />
                              }
                              title={item}
                              description={localStorage.getItem(`${item}Phone`)} />
                          </Card>
                        </Badge.Ribbon>)}
                        {session != item && (<Card
                          style={{ backgroundColor: `${getRandomColorbyString(localStorage.getItem(`${item}Phone`) + "color" ?? "")}` }}

                          actions={[
                            <Button
                              onClick={() =>
                                loginS()
                              }
                              type="link"
                            >
                              <LoginOutlined />
                              Login
                            </Button>,
                            <Button
                              danger={true}
                              onClick={() =>
                                logoutSession(localStorage.getItem(item) ?? "", item)
                              }
                              type="link"
                            >
                              <LogoutOutlined />
                              Logout
                            </Button>,
                          ]}
                        >
                          <Card.Meta

                            avatar={
                              <Avatar

                                shape="square"
                                size={38}
                                icon={session == item ? <CheckCircleOutlined /> : <IdcardOutlined />}
                              />
                            }
                            title={item}
                            description={localStorage.getItem(`${item}Phone`)} />
                        </Card>)}

                      </List.Item>
                    </Col>
                  </Row>
                )}
              />
            </div>
          </Col >
          <Conversation
            auth={auth}
            baseUrl={baseUrl}
            getRandomColorbyString={getRandomColorbyString}
            chData={chData}
            setChatId={setChatId}
            key={"Conversations"}
          ></Conversation>
          <ChatComponent
            api={api}
            accounts={accounts} auth={auth} baseUrl={baseUrl} chatId={chatId}
            getRandomColorbyString={getRandomColorbyString}
            socket={socket}
            key={"ChatComponents"}></ChatComponent>
        </Row >
      </Spin >
    </>
  );
}
