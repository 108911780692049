import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface AuthState {
  isAuthenticated: boolean;
  token: string;
  userId: number;
  userName: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: "",
  userId: 0,
  userName: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    tokenReceived: (state, { payload }: PayloadAction<AuthState>) => {
      state.isAuthenticated = true;
      state.token = payload.token;
      state.userId = payload.userId;
      state.userName = payload.userName;
    },

    loggedOut: (state) => {
      state.isAuthenticated = false;
      state.token = "";
      state.userId = 0;
      state.userName = "";
    },
  },
});

export const { tokenReceived, loggedOut } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
