import React, { useState } from "react";
import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Col,
  Row,
  Switch,
  message,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
// import { AntdFooter } from "components/Layout/Footer";
// import { AntdHeader } from "components/Layout/Header";
import { Header, Footer } from "../components/Layout";
import axios, { HttpStatusCode } from "axios";
import { useAppDispatch } from "../app/store";
import { tokenReceived } from "../app/slices/authSlice";
import { usePostApiTokenAuthAuthenticateMutation } from "../app/services/productApi";

const { Content } = Layout;
const { Title } = Typography;

// console.log(Menu);
export default function SignIn() {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authenticateAsync] = usePostApiTokenAuthAuthenticateMutation();
  const onFinish = async (values: { username: string, password: string, remember: boolean }) => {
    try {
      setIsLoading(true);
      var result = await authenticateAsync({
        authenticateModel: {
          password: values.password,
          userNameOrEmailAddress: values.username,
          rememberClient: values.remember ?? true
        }
      }).unwrap();

      setIsLoading(false);
      console.log(result);
      dispatch(
        tokenReceived({
          isAuthenticated: true,
          token: result.accessToken ?? "",
          userId: result.userId ?? 0,
          userName: ""
        })
      );
      messageApi.success("ورود موفقیت آمیز");
      navigate("/whatsapp");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }


  };
  const onFinishFailed = (errInfo: {}) => {
    console.log("Error : ", errInfo);
  };
  function onChange(checked: boolean) {
    console.log(`switch to ${checked}`);
  }
  return (
    <>
      {contextHolder}
      <div className="layout-default layout-signin">
        <Header />
        <Spin spinning={isLoading}>
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 12 }}
              >
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your username and password to sign in
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="username" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked onChange={onChange} />
                    Remember me
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                  {/* <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link>
                  </p> */}
                </Form>
              </Col>
              <Col
                className="sign-img"
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <img src={require('../assets/images/crm-bg.jpg')} alt="" />
              </Col>
            </Row>
          </Content>
        </Spin>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}
