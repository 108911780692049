import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
//import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { store } from "./app/store";
// import { AntUpload } from "./Practics";

const root = ReactDOM.createRoot(document?.getElementById("root") ?? new Element());
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <script src="coi-serviceworker.js"></script>
        <App />
        {/* <AntUpload /> */}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
