import React, { useRef, useState } from "react";
import { Alert, Button, Col, Divider, Dropdown, Form, Input, message, Modal, Popover, Row, Space, Spin, Table, TableColumnsType, Tag, Typography, Upload, UploadFile, UploadProps } from "antd";
import { FrequentlyAnswerDto, NoteItemType, useDeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerMutation, useGetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersQuery, usePostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerMutation, usePostApiServicesAppFrequentlyAnswerEditAnswerMutation, usePostApiServicesAppFrequentlyAnswerEditTitleMutation, usePostApiServicesAppNoteUpsertNoteMutation } from "../../app/services/productApi";
import { MenuInfo } from "rc-menu/lib/interface";

import {
    FileAddTwoTone, CloseSquareOutlined,
    ContainerOutlined,
    MehTwoTone, PlusOutlined,
    SendOutlined,
    AudioFilled,
    AudioTwoTone,
    DeleteTwoTone,
    MobileTwoTone,
    EditOutlined,
    WechatOutlined
} from "@ant-design/icons";
// import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
// import { ReactMediaRecorder } from "react-media-recorder";
// import 'react-voice-recorder/dist/index.css';
import Picker from '@emoji-mart/react';
import emojiData from '@emoji-mart/data';
import { useSelector } from "react-redux";
import { selectAuth } from "../../app/slices/authSlice";
import TextArea from "antd/es/input/TextArea";
interface Props {
    onSend: (message: string, fileList: UploadFile[]) => Promise<void>;
    handleAccountDropDownClick: (e: MenuInfo) => void;
    chatId: string;
    session: string;
    accounts: string[];
    sendFileBase64: (_file: string | null, _fileList: UploadFile[] | null, _message: string | null) => Promise<void>;
    sendVoiceBase64: (_base64: string) => void;
    getRandomColorbyString: (str: string) => string | null;
}

const MessageInput = ({
    onSend,
    handleAccountDropDownClick,
    sendFileBase64,
    sendVoiceBase64,
    chatId,
    session,
    getRandomColorbyString,
    accounts,
}: Props) => {
    const MicRecorder = require('mic-recorder-to-mp3');
    const [Mp3Recorder, setMp3Recorder] = useState(
        new MicRecorder({ bitRate: 320 })
    );
    const start = () => {
        if (state.isBlocked) {
            console.log('Permission Denied');
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    setState({ base64: '', isRecording: true, isBlocked: false, blobURL: '' });
                }).catch((e: any) => console.error(e));
        }
    };
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [state, setState] = useState({
        isRecording: false,
        blobURL: '',
        base64: '',
        isBlocked: false,
    });
    const stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(async ([buffer, blob]: [string, Blob]) => {
                const blobURL = URL.createObjectURL(blob)
                const base64 = (await blobToBase64(blob))?.toString();
                setState({ base64: base64 ?? "", blobURL: blobURL, isRecording: false, isBlocked: false });
                setConfirmOpen(true);

                // if (base64 !== undefined)
                //     sendVoiceBase64(base64);

            }).catch((e: any) => console.log(e));
    };




    // navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then(stream => () => {
    //         console.log('Permission Granted');
    //         setState({ base64: '', blobURL: '', isRecording: false, isBlocked: false });
    //     })
    //     .catch(err => () => {
    //         console.log('Permission Denied');
    //         setState({ base64: '', blobURL: '', isRecording: false, isBlocked: true })
    //     });

    // const recorderControls = useAudioRecorder(
    //     {
    //         noiseSuppression: true,
    //         echoCancellation: true,
    //     },
    //     (err) => console.table(err) // onNotAllowedOrFound
    // );
    const { Search } = Input;
    const auth = useSelector(selectAuth);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [textMessage, setTextMessage] = useState<string | null>(null);

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [openFApopover, setOpenFApopover] = useState<boolean | undefined>(undefined);
    const [openNotepopover, setOpenNotepopover] = useState<boolean | undefined>(undefined);
    const [openFrequentlyAnswerModal, setOpenFrequentlyAnswerModal] = useState<boolean>(false);
    const [editAnswerAsync] = usePostApiServicesAppFrequentlyAnswerEditAnswerMutation();
    const [editTitleAsync] = usePostApiServicesAppFrequentlyAnswerEditTitleMutation();
    const [showEditItems, setShowEditItems] = useState(false);
    const [noteText, setNoteText] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [expanded, setExpanded] = useState(false);
    const { data: frequentlyAnswers } = useGetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersQuery({ searchTerm: searchTerm });
    const [deleteFrequentlyAnswerAsync] = useDeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerMutation();
    const [addFrequentlyAnswerAsync, { isLoading: addFrequentlyAnswerLoading }] = usePostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerMutation();
    const [upsertNoteAsync] = usePostApiServicesAppNoteUpsertNoteMutation();
    const blobToBase64 = async (blob: Blob) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    // const ffmpegRef = useRef(new FFmpeg());
    // const load = async () => {
    //     const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/umd'
    //     const ffmpeg = ffmpegRef.current;
    //     ffmpeg.on('log', ({ message }) => {

    //         console.log(message);
    //     });
    //     toBlobURL is used to bypass CORS issue, urls with the same
    //     domain can be used directly.
    //     await ffmpeg.load({
    //         coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
    //         wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    //         workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    //     });
    // }
    // const transcode = async (blob: Blob) => {
    //     await load();
    //     const ffmpeg = ffmpegRef.current;
    //     await ffmpeg.writeFile('input.webm', await fetchFile(blob));
    //     await ffmpeg.exec(['-i', 'input.webm', 'output.mp3']);
    //     const data = await ffmpeg.readFile('output.mp3');

    //     return new Blob([data]);
    // }


    const addAudioElement = async (blob: Blob) => {
        const base64 = (await blobToBase64(blob))?.toString();
        if (base64 !== undefined)
            sendFileBase64(base64, null, null);
    };

    const columns: TableColumnsType<FrequentlyAnswerDto> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) =>
                <Typography.Paragraph
                    onClick={() => {
                        setOpenFApopover(false); setTextMessage(record.answer ?? ""); setTimeout(function () {
                            document?.getElementById("textMessage")?.focus();
                        }, 0);
                    }}
                    ellipsis={{
                        rows: 1,
                        expanded,
                        onExpand: (_, info) => setExpanded(info.expanded),
                    }}
                // editable={{
                //   autoSize: true,
                //   triggerType: ["icon"],
                //   onChange: (text) => editTitleAsync({ editTitleInput: { id: record.id ?? 0, title: text } })
                // }}
                // copyable
                >
                    <a style={{ cursor: "pointer" }}>
                        {text}
                    </a>

                </Typography.Paragraph>
        },
        {
            title: 'Message',
            dataIndex: 'answer',
            key: 'answer',
            render: (text, record) => <Typography.Paragraph
                onClick={() => {
                    setOpenFApopover(false); setTextMessage(record.answer ?? ""); setTimeout(function () {
                        document?.getElementById("textMessage")?.focus();
                    }, 0);
                }}
                ellipsis={{
                    rows: 1,
                    expanded,
                    onExpand: (_, info) => setExpanded(info.expanded),
                }}
            // editable={{
            //   autoSize: true,
            //   triggerType: ["icon"],
            //   onChange: (text) => editAnswerAsync({ editAnswerInput: { id: record.id ?? 0, answer: text } })
            // }}

            // copyable
            >
                <a style={{ cursor: "pointer" }}>
                    {text}
                </a>
            </Typography.Paragraph>,
        },
        {
            title: 'Operation',
            hidden: showEditItems != true,
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record) => <Space direction="horizontal">

                <Button type="link" onClick={() => deleteFrequentlyAnswerAsync({ id: id })}><DeleteTwoTone twoToneColor='#FF0000' /></Button></Space>
        },
    ];
    const handleCancel = () => {
        formFrequentlyAnswerForm.resetFields();
        setOpenFrequentlyAnswerModal(false);
    };

    const removeFromFileList = (file: UploadFile) => {
        var index = fileList.indexOf(file);
        var temp = [...fileList];
        temp.splice(index, 1);
        setFileList(temp);

    }
    const handleSearch = (
        selectedKeys: string[],
    ) => {
        // confirm();
        setSearchText(selectedKeys[0]);
        // setSearchedColumn(dataIndex);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);
    const uploadButton = (
        <button
            title="Upload Files..."
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <FileAddTwoTone style={{ fontSize: '20px' }} />


        </button>
    );



    const [formFrequentlyAnswerForm] = Form.useForm();
    const onFinish = async (_frequentlyAnswer: FrequentlyAnswerDto) => {
        try {
            await addFrequentlyAnswerAsync({
                createFrequentlyAnswerInput: {
                    answer: _frequentlyAnswer.answer ?? "",
                    title: _frequentlyAnswer.title ?? ""
                }
            }).unwrap();
            setOpenFrequentlyAnswerModal(false);
            formFrequentlyAnswerForm.resetFields();
            message.success("عملیات با موفقیت انجام شد.");
            handleCancel();
        } catch (e) {
            message.error("خطایی رخ داده است.");
            console.log(e);
        }
    };



    const scrollToBottom = (_id: string) => {
        document?.getElementById(_id)?.scrollTo(0, document?.getElementById(_id)?.scrollHeight ?? 0);
    };
    return (<>

        <Space direction="horizontal">
            {fileList?.map((x: UploadFile) => {
                return (<Tag closable onClose={() => removeFromFileList(x)}>{x.name} </Tag>)
            })}
        </Space>
        <Row style={{ width: "100%", borderRadius: 25, backgroundColor: "#e9e1e1", padding: 10 }} align={"middle"}>
            <Col xs={1} >
                <Popover zIndex={500}
                    open={openNotepopover}
                    content={
                        <Space direction="vertical">
                            <Input.TextArea autoSize={true} placeholder="note text..." onChange={(e) => setNoteText(e.target.value)} style={{ width: 200 }} />
                            <Button type="link" onClick={() => {
                                upsertNoteAsync({
                                    upsertNoteInput: {
                                        id: 0,
                                        itemId: chatId,
                                        itemType: NoteItemType.$4,
                                        text: noteText
                                    }
                                });
                                setOpenNotepopover(false);
                                setNoteText(null);
                                scrollToBottom("chatBoxDiv");
                            }}>Send Note</Button>
                        </Space>
                    }
                    title={<Space direction="horizontal"><span>Add Note</span>

                        <Button type="link" danger onClick={() => setOpenNotepopover(false)}> <CloseSquareOutlined ></CloseSquareOutlined></Button>
                    </Space>}
                    trigger="click">
                    <ContainerOutlined onClick={() => setOpenNotepopover(true)} style={{ fontSize: '20px' }} />
                </Popover>
            </Col>
            <Col xs={1} >
                <Popover trigger="click"
                    content={<Picker data={emojiData} onEmojiSelect={(e: any) => {
                        setTimeout(function () {
                            document?.getElementById("textMessage")?.focus();
                        }, 0);
                        setTextMessage((textMessage == null ? "" : textMessage + " ") + e.native)
                    }} />} >
                    <MehTwoTone style={{ fontSize: '20px', color: 'yellow' }} twoToneColor="#BFBF00" />
                </Popover>
            </Col>
            <Col xs={1}>
                <Popover zIndex={500}
                    open={openFApopover}
                    content={
                        <Space direction="vertical">
                            <Search placeholder="input search text..." onChange={(e) => handleSearch([e.target.value.toUpperCase()])} value={searchText ?? ""} onSearch={(value) => handleSearch([value.toUpperCase()])} style={{ width: 200 }} />
                            <Table scroll={{ x: 300 }} pagination={false} columns={columns} dataSource={searchText == null ? frequentlyAnswers?.data ?? [] : frequentlyAnswers?.data?.filter(x => (x.answer?.toUpperCase()?.includes(searchText) || x.title?.toUpperCase()?.includes(searchText)) ?? [])} />
                        </Space>
                    }
                    title={<Space direction="horizontal"><span>Frequently Answers</span>
                        {auth.userName === "admin" && <Button type="link" onClick={() => setOpenFrequentlyAnswerModal(true)}><PlusOutlined /></Button>}
                        {auth.userName === "admin" && <Button type="link" onClick={() => setShowEditItems(true)}><EditOutlined /></Button>}
                        <Button type="link" danger onClick={() => setOpenFApopover(false)}> <CloseSquareOutlined ></CloseSquareOutlined></Button>
                    </Space>}
                    trigger="click">
                    <WechatOutlined onClick={() => setOpenFApopover(true)} style={{ fontSize: '20px' }} />
                </Popover>
            </Col>

            <Col xs={1} style={{ paddingLeft: "2px" }}>
                <Upload

                    showUploadList={false}
                    listType="text"
                    fileList={fileList}
                    onChange={handleChange}
                >
                    {uploadButton}
                </Upload>


            </Col>
            <Col xs={1} style={{ zIndex: "100" }}>
                {/*   <AudioRecorder
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    downloadFileExtension="mp3"
                    showVisualizer={true}
                />*/}



                <Popover content={<Alert

                    description={<audio preload="auto" controls src={state.blobURL}></audio>}
                    type="warning"
                    action={
                        <Space direction="horizontal">
                            <Button size="small" type="link" onClick={() => {
                                setState({ ...state, base64: '', blobURL: '', isRecording: false, isBlocked: false })
                                sendVoiceBase64(state.base64)
                                setConfirmOpen(false)
                            }}>
                                Send
                            </Button>
                            <Button size="small" type="link" danger ghost onClick={() => {
                                setState({ ...state, base64: '', blobURL: '', isRecording: false, isBlocked: false })
                                setConfirmOpen(false)
                            }}>
                                Cancel
                            </Button>
                        </Space>
                    }

                />} trigger="click" open={confirmOpen}>
                    {
                        state.isRecording !== true &&
                        <AudioFilled onClick={start} style={{ fontSize: '20px' }} />

                    }
                    {state.isRecording === true &&
                        <AudioTwoTone onClick={stop} style={{ fontSize: '20px' }} twoToneColor={'#FF0000'}></AudioTwoTone>
                    }

                </Popover>



                {/* <ReactMediaRecorder
                    audio
                    render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
                        <div>
                            <p>{status}</p>
                            <button onClick={startRecording}>Start Recording</button>
                            <button onClick={stopRecording}>Stop Recording</button>
                            <audio src={mediaBlobUrl} controls autoPlay loop />
                        </div>
                    )}
                /> */}
            </Col>
            <Col xs={17}>
                <Input.TextArea
                    autoSize={true}
                    placeholder="Message..."
                    value={textMessage ?? ""}
                    dir="rtl"
                    style={{ background: "transparent", border: 0 }}
                    id="textMessage"
                    name="textMessage"
                    onChange={(e) => setTextMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && e.shiftKey === false) {
                            e.preventDefault();
                            onSend(textMessage ?? "", fileList);
                            setTextMessage(null);
                            setFileList([]);
                        }
                    }}
                />
            </Col>


            <Col xs={1}>
                <Dropdown

                    menu={{
                        onClick: (e) => handleAccountDropDownClick(e),
                        defaultSelectedKeys: [session],
                        selectable: true, items: accounts.map((it) => {
                            return {
                                key: it, label: it, isSelected: it === session
                            }
                        })
                    }} >
                    <MobileTwoTone style={{ fontSize: '20px' }} twoToneColor={getRandomColorbyString(localStorage.getItem(`${session}Phone`) + "color") ?? "fffff"} />
                </Dropdown>

            </Col>
            <Col xs={1}>


                <SendOutlined title="Send Message..." onClick={() => {
                    onSend(textMessage ?? "", fileList);
                    setTextMessage(null);
                    setFileList([]);
                }} style={{ fontSize: '20px' }}></SendOutlined>

            </Col>
        </Row>







        <Modal

            open={openFrequentlyAnswerModal}
            title={
                <>
                    <span>Add Frequently Answer</span>
                    <Divider />
                </>
            }
            onCancel={() => {
                handleCancel();
            }}
            footer={[
                <>
                    <Divider />
                    <Button
                        loading={addFrequentlyAnswerLoading}
                        form={`add-FrequentlyAnswer`}
                        key="submit"
                        htmlType="submit"
                        type="primary"
                        size={"large"}
                    >

                        Add
                    </Button>
                </>,

                <Button
                    disabled={addFrequentlyAnswerLoading}
                    key={"cancel"}
                    size={"large"}
                    onClick={() => {
                        handleCancel();
                    }}
                    danger
                >
                    Cancel
                </Button>,
            ]}
        >
            <Spin spinning={addFrequentlyAnswerLoading}>
                <Form
                    form={formFrequentlyAnswerForm}
                    name={`add-FrequentlyAnswer`}
                    preserve={false}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row>

                        <Form.Item
                            style={{ display: "inline-block", width: "100%" }}
                            name="title"
                            label={"Title"}
                            rules={[{ required: true, message: "Title is Required !!!" }]}
                        >
                            <Input style={{ width: "100%" }} placeholder="Title..." />
                        </Form.Item>



                    </Row>
                    <Row>

                        <Form.Item
                            style={{ display: "inline-block", width: "100%" }}
                            name="answer"
                            label={"Answer"}
                            rules={[{ required: true, message: "Answer is Required !!!" }]}
                        >
                            <TextArea style={{ width: "100%" }} placeholder="Answer..." rows={3}></TextArea>
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
        </Modal>

    </>);
}

export default MessageInput;