import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  LogoutOutlined,
} from "@ant-design/icons";
import {
  openDrawer,
  handleFixedNavbar,
  handleSideNavColor,
  handleSidenavType,
  handleSidebar,
  selectMain,
} from "../../Redux/features/MainSlice";
import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  List,
  Avatar,
  Button,
  Drawer,
  Typography,
  Switch,
  Input,
} from "antd";
import {
  data,
  bell,
  logsetting,
  toggler,
  setting,
} from "../../utils/HeaderData";
import { Link, NavLink } from "react-router-dom";
import { Iconify } from "../../utils/Iconify";
import ButtonGroup from "antd/es/button/button-group";
import { selectAuth, loggedOut, tokenReceived } from "../../app/slices/authSlice";


import { ProductApi, useGetApiServicesAppSessionGetCurrentLoginInformationsQuery } from "../../app/services/productApi";
import { useAppDispatch } from "../../app/store";

const { Title, Text } = Typography;
const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown"
    style={{
      backgroundColor: "#fff",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "1rem",
    }}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item style={{ borderRadius: "1rem" }}>
        <List.Item.Meta
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "1rem",
          }}
          avatar={<Avatar shape="square" src={item.avatar} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    )}
  />
);
const buttonList = [
  { color: "#1890ff", type: "primary" },
  { color: "#52c41a", type: "primary" },
  { color: "#d9363e", type: "primary" },
  { color: "#fadb14", type: "default" },
  { color: "#111", type: "default" },
];


export default function AntdHeader({ name }: { name: string }) {
  const auth = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const {
    data
  } = useGetApiServicesAppSessionGetCurrentLoginInformationsQuery(undefined, { skip: !auth.isAuthenticated });

  useEffect(() => {

    if (auth.isAuthenticated) {
      dispatch(
        tokenReceived({
          isAuthenticated: auth.isAuthenticated,
          token: auth.token,
          userId: auth.userId,
          userName: data?.user?.userName ?? ""
        })
      )
    }

  }, [data, auth]);

  return (
    <>
      {/* <div className="setting-drwer" onClick={() => dispatch(openDrawer(true))}>
        {setting}
      </div> */}
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <p style={{ textTransform: "capitalize" }}>

                {name.replace("/", "")}
              </p>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {name.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          {/* <Badge size="small" count={4}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              arrow
              overlayStyle={{ width: "20%" }}
            >
              <a href="#pablo" className="ant-dropdown-link">
                {bell}
              </a>
            </Dropdown>
          </Badge> */}
          {/* <Button type="link" onClick={() => dispatch(openDrawer(true))}>
            {logsetting}
          </Button> */}
          {/* <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => dispatch(handleSidebar(true))}
          >
            {toggler}
          </Button> */}
          {/* <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            placement={placement}
            visible={visible}
            closable={false}
            onClose={() => dispatch(openDrawer(false))}
          >
            <div >
              <div className="header-top">
                <Title level={4}>
                  Configure
                  <Text className="subtitle">See our Dashboard Option</Text>
                </Title>
              </div>
              <div className="sidebar-color">
                <Title level={5}>Sidebar Color</Title>
                <div className="theme-color mb-2">
                  <ButtonGroup>
                    {buttonList.map(({ color }, index) => {
                      return (
                        <Button
                          type={"primary"}
                          key={index}
                          onClick={() => dispatch(handleSideNavColor(color))}
                        >
                          1
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>
                <div className="sidebarnav-color mb-2">
                  <Title level={5}>Sidenav Type</Title>
                  <Text>Chose between 2 different Sidenav Types</Text>
                  <ButtonGroup className="trans">
                    <Button
                      type={sideNavType === "transparent" ? "primary" : "default"}
                      onClick={() => dispatch(handleSidenavType("transparent"))}
                    >
                      TRANSPARENT
                    </Button>
                    <Button
                      type={sideNavType === "white" ? "primary" : "default"}
                      onClick={() => dispatch(handleSidenavType("white"))}
                    >
                      WHITE
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="fixed-nav mb-2">
                  <Title level={5}>Navbar Fixed</Title>
                  <Switch onChange={(e) => dispatch(handleFixedNavbar(e))} />
                </div>
                <div className="ant-docment">
                  <ButtonGroup>
                    <Button type="default" size="large">
                      FREE DOWNLOAD
                    </Button>
                    <Button size="large">VIEW DOCUMENTATION</Button>
                  </ButtonGroup>
                </div>
                <div className="viewstar">
                  <a href="#pablo">{<Iconify icon="akar-icons:star" />} Star</a>
                  <a href="#pablo">190</a>
                </div>
                <div className="ant-thank">
                  <Title level={5}>Thank you for sharing!</Title>
                  <ButtonGroup>
                    <Button type="default">
                      {<Iconify  icon="akar-icons:twitter-fill" />} TWEET
                    </Button>
                    <Button type="default">
                      {<Iconify icon="eva:share-fill" />} SHARE
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </Drawer> */}

          <Button
            danger={true}
            onClick={() => {
              dispatch(loggedOut());
              dispatch(ProductApi.util.resetApiState());
            }}
            type="dashed"
          >
            <LogoutOutlined />
            Logout
          </Button>
          <Link to="/sign-in" className="btn-sign-in">

            <span>{data?.user?.userName}</span>
          </Link>
          {/* <Input
            className="header-search"
            placeholder="Type here..."
            prefix={<Iconify icon="akar-icons:search" />}
          /> */}
        </Col>
      </Row>
    </>
  );
}
