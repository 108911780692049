import { Alert, Button, Dropdown, Image, List, MenuProps, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
    CheckCircleOutlined, DeleteTwoTone,
    CheckCircleTwoTone,
    CheckOutlined,
    ClockCircleOutlined,
    ContainerOutlined,
} from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import axios from "axios";
import { MessageSenderDto, NoteItemType, useDeleteApiServicesAppNoteDeleteNoteMutation, usePostApiServicesAppNoteUpsertNoteMutation } from "../../app/services/productApi";

import { MessageDto } from "../../utils/MessageDto";
import Marquee from "react-fast-marquee";
import Title from "antd/es/typography/Title";
interface Props {
    phoneNumber: string;
    accounts: string[];
    baseUrl: string;
    getRandomColorbyString: (str: string) => string | null;
    handleActionDropDownClick: (e: MenuInfo, item: MessageDto) => void;
    setChatData: (value: React.SetStateAction<MessageDto[]>) => void;
    session: string;
    item: MessageDto;
    senders: MessageSenderDto[];
    displayTime: (ticksInSecs: number) => string;
}

const Message = ({
    phoneNumber,
    baseUrl,
    accounts,
    getRandomColorbyString,
    session,
    handleActionDropDownClick,
    item,
    senders,
    displayTime
}: Props) => {

    const [upsertNoteAsync] = usePostApiServicesAppNoteUpsertNoteMutation();
    const [deleteNoteAsync] = useDeleteApiServicesAppNoteDeleteNoteMutation();
    const findPosition = (obj: any): number[] | undefined => {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    };
    const scrolldiv = (_id: string) => {
        const div = document.getElementById("chatBoxDiv");
        const child = document.getElementById(_id) ?? new HTMLElement();
        var pos = findPosition(child);
        div?.scrollTo(0, pos === undefined ? 0 : pos[0] - 300);
        setTimeout(() => {
            child?.classList.add("animate__animated");
            child?.classList.add("animate__bounceIn");
        }, 500);
        setTimeout(() => {
            child?.classList.remove("animate__animated");
            child?.classList.remove("animate__bounceIn");
        }, 3000);
    };
    const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Reply',
        },
        {
            key: '2',
            label: 'Delete',
            danger: true
        },

    ];
    const [srcFile, setSRCFile] = useState("");
    const [fileLoading, setFileLoading] = useState(false);

    const [medias, setMedias] = useState<{ id: string; content: string }[]>([]);
    const getMedia = () => {
        const _phone = item.fromMe ? item.from : item.to;
        let _session = "";
        for (let a = 0; a < accounts.length; a++) {
            const element = accounts[a];
            if (localStorage.getItem(`${element}Phone`) === _phone)
                _session = element;
        }
        setFileLoading(true);
        const _index = medias.findIndex(x => x.id === item.id)
        if (_index === -1) {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem(_session)}`, accept: "*/*" },
            };

            axios
                .get(
                    `${baseUrl}api/${_session}/get-media-by-message/${item.id}`,

                    config
                )
                .then((result) => {
                    setMedias([...medias, { id: item.id, content: `data:${result.data.mimetype};base64,${result.data.base64}` }])

                    setFileLoading(false);
                    if (item.type === "document") {
                        var elem = document.createElement("a");
                        elem.href = `data:${result.data.mimetype};base64,${result.data.base64}`;
                        elem.setAttribute("download", item.filename);
                        elem.click();
                    } else { setSRCFile(`data:${result.data.mimetype};base64,${result.data.base64}`); }
                    return `data:${result.data.mimetype};base64,${result.data.base64}`;
                })
                .catch(function (error) {

                    console.log(error);
                    return "";
                });

        }
        else {
            if (item.type === "document") {
                var elem = document.createElement("a");
                elem.href = medias[_index].content;
                elem.setAttribute("download", item.filename);
                elem.click();
            } else { setSRCFile(medias[_index].content); }

            setFileLoading(false);
            return medias[_index].content
        }

    };





    return (<>

        <List.Item
            actions={
                item.fromMe
                    ? item.type !== "chat" && item.type !== "revoked" && item.type !== "note"
                        ? [
                            <Dropdown menu={{ onClick: (e) => handleActionDropDownClick(e, item), items }} placement="top" arrow>
                                <Button type="link">...</Button>
                            </Dropdown>
                            ,


                            <IconText
                                text=""
                                icon={
                                    item.ack === 1
                                        ? CheckOutlined
                                        : item.ack === 2
                                            ? CheckCircleOutlined
                                            : item.ack === 3
                                                ? CheckCircleTwoTone
                                                : ClockCircleOutlined
                                }
                            />,
                            <span>
                                {displayTime(item.t)}
                            </span>
                        ]
                        : [
                            <Dropdown menu={{ onClick: (e) => handleActionDropDownClick(e, item), items }} placement="top" arrow>
                                <Button type="link">...</Button>
                            </Dropdown>,

                            <IconText
                                text=""
                                icon={
                                    item.ack === 1
                                        ? CheckOutlined
                                        : item.ack === 2
                                            ? CheckCircleOutlined
                                            : item.ack === 3
                                                ? CheckCircleTwoTone
                                                : ClockCircleOutlined
                                }
                            />,
                            <span>
                                {displayTime(item.t)}
                            </span>
                        ]
                    : item.type !== "chat" && item.type !== "revoked" && item.type !== "note"
                        ? [
                            <Dropdown menu={{ onClick: (e) => handleActionDropDownClick(e, item), items }} placement="top" arrow>
                                <Button type="link">...</Button>
                            </Dropdown>,

                        ]
                        : item.type === "note"
                            ? [


                            ] :
                            [
                                <Dropdown menu={{ onClick: (e) => handleActionDropDownClick(e, item), items }} placement="top" arrow>
                                    <Button type="link">...</Button>
                                </Dropdown>,
                                <span>
                                    {displayTime(item.t)}
                                </span>
                            ]
            }
            id={item.id}
            style={{
                textAlign: item.fromMe ? "end" : "start",

                marginBottom: "5px",
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: item.fromMe
                    ? `${getRandomColorbyString(item.from + "color")}`
                    : `${getRandomColorbyString(item.to + "color")}`,
            }}
        >
            {item.quotedMsg != null && (
                <>
                    <Alert
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                            scrolldiv(
                                `${item.quotedParticipant === phoneNumber
                                    ? "true"
                                    : "false"
                                }_${item.chatId._serialized === undefined
                                    ? item.chatId
                                    : item.chatId._serialized
                                }_${item.quotedStanzaID}${item.isGroupMsg == true
                                    ? "_" + item.quotedParticipant
                                    : ""
                                }`
                            )
                        }
                        showIcon={false}
                        banner
                        message={
                            <Marquee pauseOnHover gradient={false}>
                                {item.quotedMsg.type !== "chat"
                                    ? "Show Replied Message..."
                                    : item.quotedMsg.body}
                            </Marquee>
                        }
                    />
                    <br></br>
                </>
            )}

            {item.type === "chat" || item.type === "revoked" ? (
                <Spin spinning={item.spin === true}>
                    <List.Item.Meta
                        style={{
                            direction: "rtl",
                            textAlign: item.fromMe ? "start" : "end",
                            paddingRight: "15px",
                            paddingLeft: "15px"
                        }}
                        title={
                            item.sender == null
                                ? "Unknown"
                                : item.fromMe ? senders?.filter(x => x.messageId === item.id)[0]?.username ?? "" :
                                    item.sender.formattedName
                        }
                        description={
                            <p dir="rtl">
                                {item.type !== "revoked"
                                    ? <div dangerouslySetInnerHTML={{ __html: item.body ?? "" }}></div>
                                    : "This Message Was Deleted"}
                            </p>
                        }
                    />
                </Spin>

            ) : item.type === "note" ? (
                <Spin spinning={item.spin === true}>
                    <List.Item.Meta
                        style={{

                            textAlign: "center",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                            background: "yellow"
                        }}
                        title={
                            <> <ContainerOutlined></ContainerOutlined>  {item.sender.formattedName} <Button title="Delete Note" type="link" onClick={() => deleteNoteAsync({ id: parseInt(item.chatId._serialized ?? "0") })} danger><DeleteTwoTone twoToneColor={'#FF0000'}></DeleteTwoTone></Button></>

                        }
                        description={
                            <Typography.Paragraph

                                editable={{
                                    autoSize: true,
                                    triggerType: ["icon"],
                                    onChange: (text: string) => upsertNoteAsync({
                                        upsertNoteInput: {
                                            id: parseInt(item.chatId._serialized ?? "0"),
                                            itemId: item.chatId.user,
                                            itemType: NoteItemType.$4,
                                            text: text
                                        }
                                    })
                                }}
                                dir="rtl">
                                <div dangerouslySetInnerHTML={{ __html: item.body ?? "" }}></div>


                            </Typography.Paragraph>
                        }
                    />
                </Spin>

            ) : item.type === "image" || item.type === "sticker" ? (
                <List.Item.Meta
                    style={{
                        direction: "rtl",
                        textAlign: item.fromMe ? "start" : "end",
                        paddingRight: "15px",
                        paddingLeft: "15px"
                    }}
                    title={
                        `${item.sender == null
                            ? "Unknown"
                            : item.fromMe ? senders?.filter(x => x.messageId === item.id)[0]?.username ?? "" : item.sender.formattedName} `
                    }
                    description={
                        <Spin spinning={fileLoading == true}>
                            {srcFile !== "" && (<Image
                                height={
                                    item.type === "sticker"
                                        ? item.height
                                        : item.height / 4
                                }
                                width={
                                    item.type === "sticker"
                                        ? item.width
                                        : item.width / 4
                                }
                                src={srcFile}
                            ></Image>)}
                            {srcFile === "" && (<Image
                                onClick={() => getMedia()}
                                height={
                                    50
                                }
                                width={
                                    50
                                }
                                src={srcFile}
                            ></Image>)}

                        </Spin>
                    }
                />
            ) : item.type === "ptt" ? (
                <List.Item.Meta
                    style={{
                        direction: "rtl",
                        textAlign: item.fromMe ? "start" : "end",
                        paddingRight: "15px",
                        paddingLeft: "15px"
                    }}
                    title={
                        `${item.sender == null
                            ? "Unknown"
                            : item.fromMe ? senders?.filter(x => x.messageId === item.id)[0]?.username ?? "" : item.sender.formattedName} `
                    }
                    description={
                        <Spin spinning={fileLoading === true}>
                            {srcFile !== "" && (
                                <audio controls preload="">
                                    <source
                                        src={srcFile}
                                        type="audio/ogg"
                                    />
                                    <source
                                        src={srcFile}
                                        type="audio/mpeg"
                                    />
                                </audio>
                            )}
                            {srcFile === "" && (
                                <Space direction="horizontal">
                                    <audio controls preload="" >
                                        <source
                                            src={""}
                                            type="audio/ogg"
                                        />
                                        <source
                                            src={""}
                                            type="audio/mpeg"
                                        />
                                    </audio>
                                    <Button type="link" onClick={() => getMedia()}>
                                        Download
                                    </Button>
                                </Space>


                            )}

                        </Spin>
                    }
                />
            ) : item.type === "video" ? (
                <List.Item.Meta
                    style={{
                        direction: "rtl",
                        textAlign: item.fromMe ? "start" : "end",
                        paddingRight: "15px",
                        paddingLeft: "15px"
                    }}
                    title={
                        `${item.sender == null
                            ? "Unknown"
                            : item.fromMe ? senders?.filter(x => x.messageId === item.id)[0]?.username ?? "" : item.sender.formattedName} `
                    }
                    description={
                        <>
                            <Spin spinning={fileLoading == true}>

                                {srcFile !== "" && (
                                    <video
                                        id={`video_${item.id}2`}
                                        width={item.width / 3}
                                        height={item.height / 3}
                                        controls
                                    >
                                        <source
                                            type={item.mimetype}
                                            src={srcFile}
                                        />
                                    </video>
                                )}
                                {srcFile === "" && (
                                    <video onClick={() => getMedia()}
                                        id={`video_${item.id}2`}
                                        width={item.width / 3}
                                        height={item.height / 3}
                                        controls
                                    >
                                        <source
                                            type={item.mimetype}
                                            src={""}
                                        />
                                    </video>
                                )}
                            </Spin>
                        </>
                    }
                />
            ) : (
                <List.Item.Meta
                    style={{
                        direction: "rtl",
                        textAlign: item.fromMe ? "start" : "end",
                        paddingRight: "15px",
                        paddingLeft: "15px"
                    }}
                    title={
                        `${item.sender == null
                            ? "Unknown"
                            : item.fromMe ? senders?.filter(x => x.messageId === item.id)[0]?.username ?? "" : item.sender.formattedName} `
                    }
                    description={
                        <div dangerouslySetInnerHTML={{ __html: item.body ?? "" }}></div>
                    }
                />
            )}
            {item.caption !== null && item.caption !== "" && (
                <Title
                    style={{
                        direction: "rtl",
                        textAlign: item.fromMe ? "start" : "end",
                        paddingRight: "15px",
                        paddingLeft: "15px"
                    }}
                    direction="rtl"
                    level={5}
                >
                    {item.type === "document" && (<Button onClick={() => getMedia()} type="link">
                        <div dangerouslySetInnerHTML={{ __html: item.caption ?? "" }}></div>
                    </Button>)}
                    {item.type !== "document" && (<div dangerouslySetInnerHTML={{ __html: item.caption ?? "" }}></div>)}
                </Title>
            )}

            <div>{ }</div>
        </List.Item>

    </>);
}

export default Message;