import { autoGeneratedApi as api } from "../autoGeneratedApi";
export const addTagTypes = [
  "Account",
  "AccountNumber",
  "AttachmentFile",
  "BaseType",
  "Chat",
  "Configuration",
  "Currency",
  "CustomerGroup",
  "DocumentField",
  "ExchangeRate",
  "FrequentlyAnswer",
  "Fund",
  "FundPlace",
  "Message",
  "Note",
  "People",
  "PeopleExtraInfo",
  "PlaceOfDeliveryPaymentType",
  "PlaceOfDeliveryPaymentTypeField",
  "Role",
  "Session",
  "Tenant",
  "TokenAuth",
  "Tree",
  "User",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postApiServicesAppAccountIsTenantAvailable: build.mutation<
        PostApiServicesAppAccountIsTenantAvailableApiResponse,
        PostApiServicesAppAccountIsTenantAvailableApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Account/IsTenantAvailable`,
          method: "POST",
          body: queryArg.isTenantAvailableInput,
        }),
        invalidatesTags: ["Account"],
      }),
      postApiServicesAppAccountRegister: build.mutation<
        PostApiServicesAppAccountRegisterApiResponse,
        PostApiServicesAppAccountRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Account/Register`,
          method: "POST",
          body: queryArg.registerInput,
        }),
        invalidatesTags: ["Account"],
      }),
      deleteApiServicesAppAccountNumberDeleteAccountNumber: build.mutation<
        DeleteApiServicesAppAccountNumberDeleteAccountNumberApiResponse,
        DeleteApiServicesAppAccountNumberDeleteAccountNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AccountNumber/DeleteAccountNumber`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["AccountNumber"],
      }),
      getApiServicesAppAccountNumberGetAccountNumber: build.query<
        GetApiServicesAppAccountNumberGetAccountNumberApiResponse,
        GetApiServicesAppAccountNumberGetAccountNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AccountNumber/GetAccountNumber`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["AccountNumber"],
      }),
      getApiServicesAppAccountNumberGetAccountNumbers: build.query<
        GetApiServicesAppAccountNumberGetAccountNumbersApiResponse,
        GetApiServicesAppAccountNumberGetAccountNumbersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AccountNumber/GetAccountNumbers`,
          params: {
            Owner: queryArg.owner,
            Agent: queryArg.agent,
            Number: queryArg["number"],
            Bank: queryArg.bank,
            FromCreationTime: queryArg.fromCreationTime,
            ToCreationTime: queryArg.toCreationTime,
            FromExpireDate: queryArg.fromExpireDate,
            ToExpireDate: queryArg.toExpireDate,
            OwnerNationalCode: queryArg.ownerNationalCode,
            ShowAll: queryArg.showAll,
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["AccountNumber"],
      }),
      postApiServicesAppAccountNumberUpsertAccountNumber: build.mutation<
        PostApiServicesAppAccountNumberUpsertAccountNumberApiResponse,
        PostApiServicesAppAccountNumberUpsertAccountNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AccountNumber/UpsertAccountNumber`,
          method: "POST",
          body: queryArg.upsertAccountNumberInput,
        }),
        invalidatesTags: ["AccountNumber"],
      }),
      deleteApiServicesAppAttachmentFileDeleteFile: build.mutation<
        DeleteApiServicesAppAttachmentFileDeleteFileApiResponse,
        DeleteApiServicesAppAttachmentFileDeleteFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AttachmentFile/DeleteFile`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["AttachmentFile"],
      }),
      getApiServicesAppAttachmentFileGetFile: build.query<
        GetApiServicesAppAttachmentFileGetFileApiResponse,
        GetApiServicesAppAttachmentFileGetFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AttachmentFile/GetFile`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["AttachmentFile"],
      }),
      postApiServicesAppAttachmentFileUploadFile: build.mutation<
        PostApiServicesAppAttachmentFileUploadFileApiResponse,
        PostApiServicesAppAttachmentFileUploadFileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/AttachmentFile/UploadFile`,
          method: "POST",
          body: queryArg.uploadFileInput,
        }),
        invalidatesTags: ["AttachmentFile"],
      }),
      deleteApiServicesAppBaseTypeDeleteBaseType: build.mutation<
        DeleteApiServicesAppBaseTypeDeleteBaseTypeApiResponse,
        DeleteApiServicesAppBaseTypeDeleteBaseTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/BaseType/DeleteBaseType`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["BaseType"],
      }),
      getApiServicesAppBaseTypeGetBaseType: build.query<
        GetApiServicesAppBaseTypeGetBaseTypeApiResponse,
        GetApiServicesAppBaseTypeGetBaseTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/BaseType/GetBaseType`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["BaseType"],
      }),
      getApiServicesAppBaseTypeGetBaseTypes: build.query<
        GetApiServicesAppBaseTypeGetBaseTypesApiResponse,
        GetApiServicesAppBaseTypeGetBaseTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/BaseType/GetBaseTypes`,
          params: {
            Type: queryArg["type"],
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["BaseType"],
      }),
      postApiServicesAppBaseTypeUpsertBaseType: build.mutation<
        PostApiServicesAppBaseTypeUpsertBaseTypeApiResponse,
        PostApiServicesAppBaseTypeUpsertBaseTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/BaseType/UpsertBaseType`,
          method: "POST",
          body: queryArg.upsertBaseTypeInput,
        }),
        invalidatesTags: ["BaseType"],
      }),
      getApiServicesAppChatGetEnums: build.query<
        GetApiServicesAppChatGetEnumsApiResponse,
        GetApiServicesAppChatGetEnumsApiArg
      >({
        query: () => ({ url: `/api/services/app/Chat/GetEnums` }),
        providesTags: ["Chat"],
      }),
      getApiServicesAppChatGetChatLabels: build.query<
        GetApiServicesAppChatGetChatLabelsApiResponse,
        GetApiServicesAppChatGetChatLabelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/GetChatLabels`,
          params: { Number: queryArg["number"] },
        }),
        providesTags: ["Chat"],
      }),
      deleteApiServicesAppChatDeleteLabel: build.mutation<
        DeleteApiServicesAppChatDeleteLabelApiResponse,
        DeleteApiServicesAppChatDeleteLabelApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/DeleteLabel`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Chat"],
      }),
      postApiServicesAppChatAddLabelToChat: build.mutation<
        PostApiServicesAppChatAddLabelToChatApiResponse,
        PostApiServicesAppChatAddLabelToChatApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/AddLabelToChat`,
          method: "POST",
          body: queryArg.addLabelToChatInput,
        }),
        invalidatesTags: ["Chat"],
      }),
      postApiServicesAppChatCreate: build.mutation<
        PostApiServicesAppChatCreateApiResponse,
        PostApiServicesAppChatCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/Create`,
          method: "POST",
          body: queryArg.createChatDto,
        }),
        invalidatesTags: ["Chat"],
      }),
      putApiServicesAppChatUpdate: build.mutation<
        PutApiServicesAppChatUpdateApiResponse,
        PutApiServicesAppChatUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/Update`,
          method: "PUT",
          body: queryArg.chatDto,
        }),
        invalidatesTags: ["Chat"],
      }),
      getApiServicesAppChatGetAll: build.query<
        GetApiServicesAppChatGetAllApiResponse,
        GetApiServicesAppChatGetAllApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/GetAll`,
          params: {
            Keyword: queryArg.keyword,
            SkipCount: queryArg.skipCount,
            MaxResultCount: queryArg.maxResultCount,
          },
        }),
        providesTags: ["Chat"],
      }),
      getApiServicesAppChatGet: build.query<
        GetApiServicesAppChatGetApiResponse,
        GetApiServicesAppChatGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/Get`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Chat"],
      }),
      deleteApiServicesAppChatDelete: build.mutation<
        DeleteApiServicesAppChatDeleteApiResponse,
        DeleteApiServicesAppChatDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Chat/Delete`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Chat"],
      }),
      postApiServicesAppConfigurationChangeUiTheme: build.mutation<
        PostApiServicesAppConfigurationChangeUiThemeApiResponse,
        PostApiServicesAppConfigurationChangeUiThemeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Configuration/ChangeUiTheme`,
          method: "POST",
          body: queryArg.changeUiThemeInput,
        }),
        invalidatesTags: ["Configuration"],
      }),
      deleteApiServicesAppCurrencyDeleteCurrency: build.mutation<
        DeleteApiServicesAppCurrencyDeleteCurrencyApiResponse,
        DeleteApiServicesAppCurrencyDeleteCurrencyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Currency/DeleteCurrency`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Currency"],
      }),
      getApiServicesAppCurrencyGetCurrencies: build.query<
        GetApiServicesAppCurrencyGetCurrenciesApiResponse,
        GetApiServicesAppCurrencyGetCurrenciesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Currency/GetCurrencies`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["Currency"],
      }),
      getApiServicesAppCurrencyGetCurrency: build.query<
        GetApiServicesAppCurrencyGetCurrencyApiResponse,
        GetApiServicesAppCurrencyGetCurrencyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Currency/GetCurrency`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Currency"],
      }),
      postApiServicesAppCurrencyUpsertCurrency: build.mutation<
        PostApiServicesAppCurrencyUpsertCurrencyApiResponse,
        PostApiServicesAppCurrencyUpsertCurrencyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Currency/UpsertCurrency`,
          method: "POST",
          body: queryArg.upsertCurrencyInput,
        }),
        invalidatesTags: ["Currency"],
      }),
      deleteApiServicesAppCustomerGroupDeleteCustomerGroup: build.mutation<
        DeleteApiServicesAppCustomerGroupDeleteCustomerGroupApiResponse,
        DeleteApiServicesAppCustomerGroupDeleteCustomerGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/CustomerGroup/DeleteCustomerGroup`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["CustomerGroup"],
      }),
      getApiServicesAppCustomerGroupGetCustomerGroup: build.query<
        GetApiServicesAppCustomerGroupGetCustomerGroupApiResponse,
        GetApiServicesAppCustomerGroupGetCustomerGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/CustomerGroup/GetCustomerGroup`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["CustomerGroup"],
      }),
      getApiServicesAppCustomerGroupGetCustomerGroups: build.query<
        GetApiServicesAppCustomerGroupGetCustomerGroupsApiResponse,
        GetApiServicesAppCustomerGroupGetCustomerGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/CustomerGroup/GetCustomerGroups`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["CustomerGroup"],
      }),
      postApiServicesAppCustomerGroupUpsertCustomerGroup: build.mutation<
        PostApiServicesAppCustomerGroupUpsertCustomerGroupApiResponse,
        PostApiServicesAppCustomerGroupUpsertCustomerGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/CustomerGroup/UpsertCustomerGroup`,
          method: "POST",
          body: queryArg.upsertCustomerGroupInput,
        }),
        invalidatesTags: ["CustomerGroup"],
      }),
      deleteApiServicesAppDocumentFieldDeleteDocumentField: build.mutation<
        DeleteApiServicesAppDocumentFieldDeleteDocumentFieldApiResponse,
        DeleteApiServicesAppDocumentFieldDeleteDocumentFieldApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/DocumentField/DeleteDocumentField`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["DocumentField"],
      }),
      getApiServicesAppDocumentFieldGetDocumentField: build.query<
        GetApiServicesAppDocumentFieldGetDocumentFieldApiResponse,
        GetApiServicesAppDocumentFieldGetDocumentFieldApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/DocumentField/GetDocumentField`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["DocumentField"],
      }),
      getApiServicesAppDocumentFieldGetDocumentFields: build.query<
        GetApiServicesAppDocumentFieldGetDocumentFieldsApiResponse,
        GetApiServicesAppDocumentFieldGetDocumentFieldsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/DocumentField/GetDocumentFields`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["DocumentField"],
      }),
      postApiServicesAppDocumentFieldUpsertDocumentField: build.mutation<
        PostApiServicesAppDocumentFieldUpsertDocumentFieldApiResponse,
        PostApiServicesAppDocumentFieldUpsertDocumentFieldApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/DocumentField/UpsertDocumentField`,
          method: "POST",
          body: queryArg.upsertDocumentFieldInput,
        }),
        invalidatesTags: ["DocumentField"],
      }),
      postApiServicesAppExchangeRateChangeExchangeRate: build.mutation<
        PostApiServicesAppExchangeRateChangeExchangeRateApiResponse,
        PostApiServicesAppExchangeRateChangeExchangeRateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/ExchangeRate/ChangeExchangeRate`,
          method: "POST",
          body: queryArg.changeExchangeRateInput,
        }),
        invalidatesTags: ["ExchangeRate"],
      }),
      getApiServicesAppExchangeRateGetExchangeRates: build.query<
        GetApiServicesAppExchangeRateGetExchangeRatesApiResponse,
        GetApiServicesAppExchangeRateGetExchangeRatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/ExchangeRate/GetExchangeRates`,
          params: { Date: queryArg.date },
        }),
        providesTags: ["ExchangeRate"],
      }),
      postApiServicesAppFrequentlyAnswerEditTitle: build.mutation<
        PostApiServicesAppFrequentlyAnswerEditTitleApiResponse,
        PostApiServicesAppFrequentlyAnswerEditTitleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FrequentlyAnswer/EditTitle`,
          method: "POST",
          body: queryArg.editTitleInput,
        }),
        invalidatesTags: ["FrequentlyAnswer"],
      }),
      postApiServicesAppFrequentlyAnswerEditAnswer: build.mutation<
        PostApiServicesAppFrequentlyAnswerEditAnswerApiResponse,
        PostApiServicesAppFrequentlyAnswerEditAnswerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FrequentlyAnswer/EditAnswer`,
          method: "POST",
          body: queryArg.editAnswerInput,
        }),
        invalidatesTags: ["FrequentlyAnswer"],
      }),
      getApiServicesAppFrequentlyAnswerGetFrequentlyAnswers: build.query<
        GetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersApiResponse,
        GetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FrequentlyAnswer/GetFrequentlyAnswers`,
          params: { SearchTerm: queryArg.searchTerm },
        }),
        providesTags: ["FrequentlyAnswer"],
      }),
      postApiServicesAppFrequentlyAnswerCreateFrequentlyAnswer: build.mutation<
        PostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerApiResponse,
        PostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FrequentlyAnswer/CreateFrequentlyAnswer`,
          method: "POST",
          body: queryArg.createFrequentlyAnswerInput,
        }),
        invalidatesTags: ["FrequentlyAnswer"],
      }),
      deleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswer:
        build.mutation<
          DeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerApiResponse,
          DeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/FrequentlyAnswer/DeleteFrequentlyAnswer`,
            method: "DELETE",
            params: { Id: queryArg.id },
          }),
          invalidatesTags: ["FrequentlyAnswer"],
        }),
      deleteApiServicesAppFundDeleteFund: build.mutation<
        DeleteApiServicesAppFundDeleteFundApiResponse,
        DeleteApiServicesAppFundDeleteFundApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Fund/DeleteFund`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Fund"],
      }),
      getApiServicesAppFundGetFund: build.query<
        GetApiServicesAppFundGetFundApiResponse,
        GetApiServicesAppFundGetFundApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Fund/GetFund`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Fund"],
      }),
      getApiServicesAppFundGetFunds: build.query<
        GetApiServicesAppFundGetFundsApiResponse,
        GetApiServicesAppFundGetFundsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Fund/GetFunds`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["Fund"],
      }),
      postApiServicesAppFundUpsertFund: build.mutation<
        PostApiServicesAppFundUpsertFundApiResponse,
        PostApiServicesAppFundUpsertFundApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Fund/UpsertFund`,
          method: "POST",
          body: queryArg.upsertFundInput,
        }),
        invalidatesTags: ["Fund"],
      }),
      getApiServicesAppFundPlaceGetFundForPlace: build.query<
        GetApiServicesAppFundPlaceGetFundForPlaceApiResponse,
        GetApiServicesAppFundPlaceGetFundForPlaceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FundPlace/GetFundForPlace`,
          params: { PlaceOfFundTreeId: queryArg.placeOfFundTreeId },
        }),
        providesTags: ["FundPlace"],
      }),
      postApiServicesAppFundPlaceSetFundForPlace: build.mutation<
        PostApiServicesAppFundPlaceSetFundForPlaceApiResponse,
        PostApiServicesAppFundPlaceSetFundForPlaceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/FundPlace/SetFundForPlace`,
          method: "POST",
          body: queryArg.setFundForPlaceInput,
        }),
        invalidatesTags: ["FundPlace"],
      }),
      postApiServicesAppMessagePullMessagesByMessageIds: build.mutation<
        PostApiServicesAppMessagePullMessagesByMessageIdsApiResponse,
        PostApiServicesAppMessagePullMessagesByMessageIdsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/PullMessagesByMessageIds`,
          method: "POST",
          body: queryArg.getMessagesByMessageIdsInput,
        }),
        invalidatesTags: ["Message"],
      }),
      postApiServicesAppMessageCreate: build.mutation<
        PostApiServicesAppMessageCreateApiResponse,
        PostApiServicesAppMessageCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/Create`,
          method: "POST",
          body: queryArg.createMessageDto,
        }),
        invalidatesTags: ["Message"],
      }),
      deleteApiServicesAppMessageDelete: build.mutation<
        DeleteApiServicesAppMessageDeleteApiResponse,
        DeleteApiServicesAppMessageDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/Delete`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Message"],
      }),
      getApiServicesAppMessageGet: build.query<
        GetApiServicesAppMessageGetApiResponse,
        GetApiServicesAppMessageGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/Get`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Message"],
      }),
      getApiServicesAppMessageGetAll: build.query<
        GetApiServicesAppMessageGetAllApiResponse,
        GetApiServicesAppMessageGetAllApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/GetAll`,
          params: {
            Keyword: queryArg.keyword,
            SkipCount: queryArg.skipCount,
            MaxResultCount: queryArg.maxResultCount,
          },
        }),
        providesTags: ["Message"],
      }),
      putApiServicesAppMessageUpdate: build.mutation<
        PutApiServicesAppMessageUpdateApiResponse,
        PutApiServicesAppMessageUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Message/Update`,
          method: "PUT",
          body: queryArg.messageDto,
        }),
        invalidatesTags: ["Message"],
      }),
      deleteApiServicesAppNoteDeleteNote: build.mutation<
        DeleteApiServicesAppNoteDeleteNoteApiResponse,
        DeleteApiServicesAppNoteDeleteNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Note/DeleteNote`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Note"],
      }),
      getApiServicesAppNoteGetNote: build.query<
        GetApiServicesAppNoteGetNoteApiResponse,
        GetApiServicesAppNoteGetNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Note/GetNote`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Note"],
      }),
      getApiServicesAppNoteGetNotes: build.query<
        GetApiServicesAppNoteGetNotesApiResponse,
        GetApiServicesAppNoteGetNotesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Note/GetNotes`,
          params: {
            ItemId: queryArg.itemId,
            ItemType: queryArg.itemType,
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["Note"],
      }),
      postApiServicesAppNoteUpsertNote: build.mutation<
        PostApiServicesAppNoteUpsertNoteApiResponse,
        PostApiServicesAppNoteUpsertNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Note/UpsertNote`,
          method: "POST",
          body: queryArg.upsertNoteInput,
        }),
        invalidatesTags: ["Note"],
      }),
      deleteApiServicesAppPeopleDeletePeople: build.mutation<
        DeleteApiServicesAppPeopleDeletePeopleApiResponse,
        DeleteApiServicesAppPeopleDeletePeopleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/People/DeletePeople`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["People"],
      }),
      getApiServicesAppPeopleGetPeople: build.query<
        GetApiServicesAppPeopleGetPeopleApiResponse,
        GetApiServicesAppPeopleGetPeopleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/People/GetPeople`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["People"],
      }),
      getApiServicesAppPeopleGetPeoples: build.query<
        GetApiServicesAppPeopleGetPeoplesApiResponse,
        GetApiServicesAppPeopleGetPeoplesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/People/GetPeoples`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["People"],
      }),
      postApiServicesAppPeopleUpsertPeople: build.mutation<
        PostApiServicesAppPeopleUpsertPeopleApiResponse,
        PostApiServicesAppPeopleUpsertPeopleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/People/UpsertPeople`,
          method: "POST",
          body: queryArg.upsertPeopleInput,
        }),
        invalidatesTags: ["People"],
      }),
      deleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfo: build.mutation<
        DeleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfoApiResponse,
        DeleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/PeopleExtraInfo/DeletePeopleExtraInfo`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["PeopleExtraInfo"],
      }),
      getApiServicesAppPeopleExtraInfoGetPeopleExtraInfo: build.query<
        GetApiServicesAppPeopleExtraInfoGetPeopleExtraInfoApiResponse,
        GetApiServicesAppPeopleExtraInfoGetPeopleExtraInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/PeopleExtraInfo/GetPeopleExtraInfo`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["PeopleExtraInfo"],
      }),
      postApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfo: build.mutation<
        PostApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfoApiResponse,
        PostApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/PeopleExtraInfo/UpsertPeopoleExtraInfo`,
          method: "POST",
          body: queryArg.upsertPeopleExtraInfoInput,
        }),
        invalidatesTags: ["PeopleExtraInfo"],
      }),
      deleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentType:
        build.mutation<
          DeleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentTypeApiResponse,
          DeleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentTypeApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentType/DeletePlaceOfDeliveryPaymentType`,
            method: "DELETE",
            params: { Id: queryArg.id },
          }),
          invalidatesTags: ["PlaceOfDeliveryPaymentType"],
        }),
      getApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentType:
        build.query<
          GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypeApiResponse,
          GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypeApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentType/GetPlaceOfDeliveryPaymentType`,
            params: { Id: queryArg.id },
          }),
          providesTags: ["PlaceOfDeliveryPaymentType"],
        }),
      getApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypes:
        build.query<
          GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypesApiResponse,
          GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypesApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentType/GetPlaceOfDeliveryPaymentTypes`,
            params: {
              PlaceOfDeliveryTreeId: queryArg.placeOfDeliveryTreeId,
              Orderby: queryArg.orderby,
              IsDescending: queryArg.isDescending,
              PageNumber: queryArg.pageNumber,
              ItemCount: queryArg.itemCount,
              Filter: queryArg.filter,
            },
          }),
          providesTags: ["PlaceOfDeliveryPaymentType"],
        }),
      postApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentType:
        build.mutation<
          PostApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentTypeApiResponse,
          PostApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentTypeApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentType/UpsertPlaceOfDeliveryPaymentType`,
            method: "POST",
            body: queryArg.upsertPlaceOfDeliveryPaymentTypeInput,
          }),
          invalidatesTags: ["PlaceOfDeliveryPaymentType"],
        }),
      deleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeField:
        build.mutation<
          DeleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeFieldApiResponse,
          DeleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeFieldApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentTypeField/DeletePlaceOfDeliveryPaymentTypeField`,
            method: "DELETE",
            params: { Id: queryArg.id },
          }),
          invalidatesTags: ["PlaceOfDeliveryPaymentTypeField"],
        }),
      getApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeField:
        build.query<
          GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldApiResponse,
          GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentTypeField/GetPlaceOfDeliveryPaymentTypeField`,
            params: { Id: queryArg.id },
          }),
          providesTags: ["PlaceOfDeliveryPaymentTypeField"],
        }),
      getApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFields:
        build.query<
          GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldsApiResponse,
          GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldsApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentTypeField/GetPlaceOfDeliveryPaymentTypeFields`,
            params: {
              PlaceOfDeliveryPaymentTypeId:
                queryArg.placeOfDeliveryPaymentTypeId,
              Orderby: queryArg.orderby,
              IsDescending: queryArg.isDescending,
              PageNumber: queryArg.pageNumber,
              ItemCount: queryArg.itemCount,
              Filter: queryArg.filter,
            },
          }),
          providesTags: ["PlaceOfDeliveryPaymentTypeField"],
        }),
      postApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeField:
        build.mutation<
          PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeFieldApiResponse,
          PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeFieldApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentTypeField/UpPlaceOfDeliveryPaymentTypeField`,
            method: "POST",
            body: queryArg.upPlaceOfDeliveryPaymentTypeFieldInput,
          }),
          invalidatesTags: ["PlaceOfDeliveryPaymentTypeField"],
        }),
      postApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeField:
        build.mutation<
          PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeFieldApiResponse,
          PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeFieldApiArg
        >({
          query: (queryArg) => ({
            url: `/api/services/app/PlaceOfDeliveryPaymentTypeField/UpsertPlaceOfDeliveryPaymentTypeField`,
            method: "POST",
            body: queryArg.upsertPlaceOfDeliveryPaymentTypeFieldInput,
          }),
          invalidatesTags: ["PlaceOfDeliveryPaymentTypeField"],
        }),
      deleteApiServicesAppRoleDeleteRole: build.mutation<
        DeleteApiServicesAppRoleDeleteRoleApiResponse,
        DeleteApiServicesAppRoleDeleteRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Role/DeleteRole`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Role"],
      }),
      getApiServicesAppRoleGetRole: build.query<
        GetApiServicesAppRoleGetRoleApiResponse,
        GetApiServicesAppRoleGetRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Role/GetRole`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Role"],
      }),
      getApiServicesAppRoleGetRoles: build.query<
        GetApiServicesAppRoleGetRolesApiResponse,
        GetApiServicesAppRoleGetRolesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Role/GetRoles`,
          params: {
            Name: queryArg.name,
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["Role"],
      }),
      postApiServicesAppRoleUpsertRole: build.mutation<
        PostApiServicesAppRoleUpsertRoleApiResponse,
        PostApiServicesAppRoleUpsertRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Role/UpsertRole`,
          method: "POST",
          body: queryArg.upsertRoleInput,
        }),
        invalidatesTags: ["Role"],
      }),
      getApiServicesAppSessionGetCurrentLoginInformations: build.query<
        GetApiServicesAppSessionGetCurrentLoginInformationsApiResponse,
        GetApiServicesAppSessionGetCurrentLoginInformationsApiArg
      >({
        query: () => ({
          url: `/api/services/app/Session/GetCurrentLoginInformations`,
        }),
        providesTags: ["Session"],
      }),
      deleteApiServicesAppTenantDeleteTenant: build.mutation<
        DeleteApiServicesAppTenantDeleteTenantApiResponse,
        DeleteApiServicesAppTenantDeleteTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tenant/DeleteTenant`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Tenant"],
      }),
      getApiServicesAppTenantGetTenant: build.query<
        GetApiServicesAppTenantGetTenantApiResponse,
        GetApiServicesAppTenantGetTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tenant/GetTenant`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Tenant"],
      }),
      getApiServicesAppTenantGetTenants: build.query<
        GetApiServicesAppTenantGetTenantsApiResponse,
        GetApiServicesAppTenantGetTenantsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tenant/GetTenants`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["Tenant"],
      }),
      postApiServicesAppTenantUpsertTenant: build.mutation<
        PostApiServicesAppTenantUpsertTenantApiResponse,
        PostApiServicesAppTenantUpsertTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tenant/UpsertTenant`,
          method: "POST",
          body: queryArg.upsertTenantInput,
        }),
        invalidatesTags: ["Tenant"],
      }),
      postApiTokenAuthAuthenticate: build.mutation<
        PostApiTokenAuthAuthenticateApiResponse,
        PostApiTokenAuthAuthenticateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/TokenAuth/Authenticate`,
          method: "POST",
          body: queryArg.authenticateModel,
        }),
        invalidatesTags: ["TokenAuth"],
      }),
      deleteApiServicesAppTreeDeleteTree: build.mutation<
        DeleteApiServicesAppTreeDeleteTreeApiResponse,
        DeleteApiServicesAppTreeDeleteTreeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tree/DeleteTree`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["Tree"],
      }),
      getApiServicesAppTreeGetTree: build.query<
        GetApiServicesAppTreeGetTreeApiResponse,
        GetApiServicesAppTreeGetTreeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tree/GetTree`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["Tree"],
      }),
      getApiServicesAppTreeGetTrees: build.query<
        GetApiServicesAppTreeGetTreesApiResponse,
        GetApiServicesAppTreeGetTreesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tree/GetTrees`,
          params: { TreeType: queryArg.treeType },
        }),
        providesTags: ["Tree"],
      }),
      postApiServicesAppTreeUpsertTree: build.mutation<
        PostApiServicesAppTreeUpsertTreeApiResponse,
        PostApiServicesAppTreeUpsertTreeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/Tree/UpsertTree`,
          method: "POST",
          body: queryArg.upsertTreeInput,
        }),
        invalidatesTags: ["Tree"],
      }),
      deleteApiServicesAppUserDeleteUser: build.mutation<
        DeleteApiServicesAppUserDeleteUserApiResponse,
        DeleteApiServicesAppUserDeleteUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/User/DeleteUser`,
          method: "DELETE",
          params: { Id: queryArg.id },
        }),
        invalidatesTags: ["User"],
      }),
      getApiServicesAppUserGetUser: build.query<
        GetApiServicesAppUserGetUserApiResponse,
        GetApiServicesAppUserGetUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/User/GetUser`,
          params: { Id: queryArg.id },
        }),
        providesTags: ["User"],
      }),
      getApiServicesAppUserGetUsers: build.query<
        GetApiServicesAppUserGetUsersApiResponse,
        GetApiServicesAppUserGetUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/User/GetUsers`,
          params: {
            Orderby: queryArg.orderby,
            IsDescending: queryArg.isDescending,
            PageNumber: queryArg.pageNumber,
            ItemCount: queryArg.itemCount,
            Filter: queryArg.filter,
          },
        }),
        providesTags: ["User"],
      }),
      postApiServicesAppUserUpsertUser: build.mutation<
        PostApiServicesAppUserUpsertUserApiResponse,
        PostApiServicesAppUserUpsertUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/services/app/User/UpsertUser`,
          method: "POST",
          body: queryArg.upsertUserInput,
        }),
        invalidatesTags: ["User"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ProductApi };
export type PostApiServicesAppAccountIsTenantAvailableApiResponse =
  /** status 200 Success */ IsTenantAvailableOutput;
export type PostApiServicesAppAccountIsTenantAvailableApiArg = {
  isTenantAvailableInput: IsTenantAvailableInput;
};
export type PostApiServicesAppAccountRegisterApiResponse =
  /** status 200 Success */ RegisterOutput;
export type PostApiServicesAppAccountRegisterApiArg = {
  registerInput: RegisterInput;
};
export type DeleteApiServicesAppAccountNumberDeleteAccountNumberApiResponse =
  /** status 200 Success */ DeleteAccountNumberOutput;
export type DeleteApiServicesAppAccountNumberDeleteAccountNumberApiArg = {
  id?: number;
};
export type GetApiServicesAppAccountNumberGetAccountNumberApiResponse =
  /** status 200 Success */ GetAccountNumberOutput;
export type GetApiServicesAppAccountNumberGetAccountNumberApiArg = {
  id?: number;
};
export type GetApiServicesAppAccountNumberGetAccountNumbersApiResponse =
  /** status 200 Success */ GetAccountNumbersOutput;
export type GetApiServicesAppAccountNumberGetAccountNumbersApiArg = {
  owner?: string;
  agent?: string;
  number?: string;
  bank?: BankType;
  fromCreationTime?: string;
  toCreationTime?: string;
  fromExpireDate?: string;
  toExpireDate?: string;
  ownerNationalCode?: string;
  showAll?: boolean;
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppAccountNumberUpsertAccountNumberApiResponse =
  /** status 200 Success */ UpsertAccountNumberOutput;
export type PostApiServicesAppAccountNumberUpsertAccountNumberApiArg = {
  upsertAccountNumberInput: UpsertAccountNumberInput;
};
export type DeleteApiServicesAppAttachmentFileDeleteFileApiResponse =
  /** status 200 Success */ DeleteFileOutput;
export type DeleteApiServicesAppAttachmentFileDeleteFileApiArg = {
  id?: string;
};
export type GetApiServicesAppAttachmentFileGetFileApiResponse =
  /** status 200 Success */ GetFileOutputRead;
export type GetApiServicesAppAttachmentFileGetFileApiArg = {
  id?: string;
};
export type PostApiServicesAppAttachmentFileUploadFileApiResponse =
  /** status 200 Success */ UploadFileOutput;
export type PostApiServicesAppAttachmentFileUploadFileApiArg = {
  uploadFileInput: UploadFileInput;
};
export type DeleteApiServicesAppBaseTypeDeleteBaseTypeApiResponse =
  /** status 200 Success */ DeleteBaseTypeOutput;
export type DeleteApiServicesAppBaseTypeDeleteBaseTypeApiArg = {
  id?: number;
};
export type GetApiServicesAppBaseTypeGetBaseTypeApiResponse =
  /** status 200 Success */ GetBaseTypeOutput;
export type GetApiServicesAppBaseTypeGetBaseTypeApiArg = {
  id?: number;
};
export type GetApiServicesAppBaseTypeGetBaseTypesApiResponse =
  /** status 200 Success */ GetBaseTypesOutput;
export type GetApiServicesAppBaseTypeGetBaseTypesApiArg = {
  type?: BaseTypeEnum;
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppBaseTypeUpsertBaseTypeApiResponse =
  /** status 200 Success */ UpsertBaseTypeOutput;
export type PostApiServicesAppBaseTypeUpsertBaseTypeApiArg = {
  upsertBaseTypeInput: UpsertBaseTypeInput;
};
export type GetApiServicesAppChatGetEnumsApiResponse =
  /** status 200 Success */ GetEnumsOutputRead;
export type GetApiServicesAppChatGetEnumsApiArg = void;
export type GetApiServicesAppChatGetChatLabelsApiResponse =
  /** status 200 Success */ GetChatLabelsOutput;
export type GetApiServicesAppChatGetChatLabelsApiArg = {
  number: string;
};
export type DeleteApiServicesAppChatDeleteLabelApiResponse = unknown;
export type DeleteApiServicesAppChatDeleteLabelApiArg = {
  id?: number;
};
export type PostApiServicesAppChatAddLabelToChatApiResponse = unknown;
export type PostApiServicesAppChatAddLabelToChatApiArg = {
  addLabelToChatInput: AddLabelToChatInput;
};
export type PostApiServicesAppChatCreateApiResponse =
  /** status 200 Success */ ChatDto;
export type PostApiServicesAppChatCreateApiArg = {
  createChatDto: CreateChatDto;
};
export type PutApiServicesAppChatUpdateApiResponse =
  /** status 200 Success */ ChatDto;
export type PutApiServicesAppChatUpdateApiArg = {
  chatDto: ChatDto;
};
export type GetApiServicesAppChatGetAllApiResponse =
  /** status 200 Success */ ChatDtoPagedResultDto;
export type GetApiServicesAppChatGetAllApiArg = {
  keyword?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type GetApiServicesAppChatGetApiResponse =
  /** status 200 Success */ ChatDto;
export type GetApiServicesAppChatGetApiArg = {
  id?: number;
};
export type DeleteApiServicesAppChatDeleteApiResponse = unknown;
export type DeleteApiServicesAppChatDeleteApiArg = {
  id?: number;
};
export type PostApiServicesAppConfigurationChangeUiThemeApiResponse = unknown;
export type PostApiServicesAppConfigurationChangeUiThemeApiArg = {
  changeUiThemeInput: ChangeUiThemeInput;
};
export type DeleteApiServicesAppCurrencyDeleteCurrencyApiResponse =
  /** status 200 Success */ DeleteCurrencyOutput;
export type DeleteApiServicesAppCurrencyDeleteCurrencyApiArg = {
  id: number;
};
export type GetApiServicesAppCurrencyGetCurrenciesApiResponse =
  /** status 200 Success */ GetCurrenciesOutput;
export type GetApiServicesAppCurrencyGetCurrenciesApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type GetApiServicesAppCurrencyGetCurrencyApiResponse =
  /** status 200 Success */ GetCurrencyOutput;
export type GetApiServicesAppCurrencyGetCurrencyApiArg = {
  id?: number;
};
export type PostApiServicesAppCurrencyUpsertCurrencyApiResponse =
  /** status 200 Success */ UpsertCurrencyOutput;
export type PostApiServicesAppCurrencyUpsertCurrencyApiArg = {
  upsertCurrencyInput: UpsertCurrencyInput;
};
export type DeleteApiServicesAppCustomerGroupDeleteCustomerGroupApiResponse =
  /** status 200 Success */ DeleteCustomerGroupOutput;
export type DeleteApiServicesAppCustomerGroupDeleteCustomerGroupApiArg = {
  id: number;
};
export type GetApiServicesAppCustomerGroupGetCustomerGroupApiResponse =
  /** status 200 Success */ GetCustomerGroupOutput;
export type GetApiServicesAppCustomerGroupGetCustomerGroupApiArg = {
  id?: number;
};
export type GetApiServicesAppCustomerGroupGetCustomerGroupsApiResponse =
  /** status 200 Success */ GetCustomerGroupsOutput;
export type GetApiServicesAppCustomerGroupGetCustomerGroupsApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppCustomerGroupUpsertCustomerGroupApiResponse =
  /** status 200 Success */ UpsertCustomerGroupOutput;
export type PostApiServicesAppCustomerGroupUpsertCustomerGroupApiArg = {
  upsertCustomerGroupInput: UpsertCustomerGroupInput;
};
export type DeleteApiServicesAppDocumentFieldDeleteDocumentFieldApiResponse =
  /** status 200 Success */ DeleteDocumentFieldOutput;
export type DeleteApiServicesAppDocumentFieldDeleteDocumentFieldApiArg = {
  id?: number;
};
export type GetApiServicesAppDocumentFieldGetDocumentFieldApiResponse =
  /** status 200 Success */ GetDocumentFieldOutput;
export type GetApiServicesAppDocumentFieldGetDocumentFieldApiArg = {
  id?: number;
};
export type GetApiServicesAppDocumentFieldGetDocumentFieldsApiResponse =
  /** status 200 Success */ GetDocumentFieldsOutput;
export type GetApiServicesAppDocumentFieldGetDocumentFieldsApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppDocumentFieldUpsertDocumentFieldApiResponse =
  /** status 200 Success */ UpsertDocumentFieldOutput;
export type PostApiServicesAppDocumentFieldUpsertDocumentFieldApiArg = {
  upsertDocumentFieldInput: UpsertDocumentFieldInput;
};
export type PostApiServicesAppExchangeRateChangeExchangeRateApiResponse =
  /** status 200 Success */ ChangeExchangeRateOutput;
export type PostApiServicesAppExchangeRateChangeExchangeRateApiArg = {
  changeExchangeRateInput: ChangeExchangeRateInput;
};
export type GetApiServicesAppExchangeRateGetExchangeRatesApiResponse =
  /** status 200 Success */ GetExchangeRatesOutput;
export type GetApiServicesAppExchangeRateGetExchangeRatesApiArg = {
  date: string;
};
export type PostApiServicesAppFrequentlyAnswerEditTitleApiResponse = unknown;
export type PostApiServicesAppFrequentlyAnswerEditTitleApiArg = {
  editTitleInput: EditTitleInput;
};
export type PostApiServicesAppFrequentlyAnswerEditAnswerApiResponse = unknown;
export type PostApiServicesAppFrequentlyAnswerEditAnswerApiArg = {
  editAnswerInput: EditAnswerInput;
};
export type GetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersApiResponse =
  /** status 200 Success */ GetFrequentlyAnswersOutput;
export type GetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersApiArg = {
  searchTerm?: string;
};
export type PostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerApiResponse =
  unknown;
export type PostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerApiArg = {
  createFrequentlyAnswerInput: CreateFrequentlyAnswerInput;
};
export type DeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerApiResponse =
  unknown;
export type DeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerApiArg = {
  id?: number;
};
export type DeleteApiServicesAppFundDeleteFundApiResponse =
  /** status 200 Success */ DeleteFundOutput;
export type DeleteApiServicesAppFundDeleteFundApiArg = {
  id?: number;
};
export type GetApiServicesAppFundGetFundApiResponse =
  /** status 200 Success */ GetFundOutput;
export type GetApiServicesAppFundGetFundApiArg = {
  id?: number;
};
export type GetApiServicesAppFundGetFundsApiResponse =
  /** status 200 Success */ GetFundsOutput;
export type GetApiServicesAppFundGetFundsApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppFundUpsertFundApiResponse =
  /** status 200 Success */ UpsertFundOutput;
export type PostApiServicesAppFundUpsertFundApiArg = {
  upsertFundInput: UpsertFundInput;
};
export type GetApiServicesAppFundPlaceGetFundForPlaceApiResponse =
  /** status 200 Success */ GetFundForPlaceOutput;
export type GetApiServicesAppFundPlaceGetFundForPlaceApiArg = {
  placeOfFundTreeId: number;
};
export type PostApiServicesAppFundPlaceSetFundForPlaceApiResponse =
  /** status 200 Success */ SetFundForPlaceOutput;
export type PostApiServicesAppFundPlaceSetFundForPlaceApiArg = {
  setFundForPlaceInput: SetFundForPlaceInput;
};
export type PostApiServicesAppMessagePullMessagesByMessageIdsApiResponse =
  /** status 200 Success */ GetMessagesByMessageIdsOutput;
export type PostApiServicesAppMessagePullMessagesByMessageIdsApiArg = {
  getMessagesByMessageIdsInput: GetMessagesByMessageIdsInput;
};
export type PostApiServicesAppMessageCreateApiResponse =
  /** status 200 Success */ MessageDto;
export type PostApiServicesAppMessageCreateApiArg = {
  createMessageDto: CreateMessageDto;
};
export type DeleteApiServicesAppMessageDeleteApiResponse = unknown;
export type DeleteApiServicesAppMessageDeleteApiArg = {
  id?: number;
};
export type GetApiServicesAppMessageGetApiResponse =
  /** status 200 Success */ MessageDto;
export type GetApiServicesAppMessageGetApiArg = {
  id?: number;
};
export type GetApiServicesAppMessageGetAllApiResponse =
  /** status 200 Success */ MessageDtoPagedResultDto;
export type GetApiServicesAppMessageGetAllApiArg = {
  keyword?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type PutApiServicesAppMessageUpdateApiResponse =
  /** status 200 Success */ MessageDto;
export type PutApiServicesAppMessageUpdateApiArg = {
  messageDto: MessageDto;
};
export type DeleteApiServicesAppNoteDeleteNoteApiResponse =
  /** status 200 Success */ DeleteNoteOutput;
export type DeleteApiServicesAppNoteDeleteNoteApiArg = {
  id?: number;
};
export type GetApiServicesAppNoteGetNoteApiResponse =
  /** status 200 Success */ GetNoteOutput;
export type GetApiServicesAppNoteGetNoteApiArg = {
  id?: number;
};
export type GetApiServicesAppNoteGetNotesApiResponse =
  /** status 200 Success */ GetNotesOutput;
export type GetApiServicesAppNoteGetNotesApiArg = {
  itemId?: string;
  itemType?: NoteItemType;
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppNoteUpsertNoteApiResponse =
  /** status 200 Success */ UpsertNoteOutput;
export type PostApiServicesAppNoteUpsertNoteApiArg = {
  upsertNoteInput: UpsertNoteInput;
};
export type DeleteApiServicesAppPeopleDeletePeopleApiResponse =
  /** status 200 Success */ DeletePeopleOutput;
export type DeleteApiServicesAppPeopleDeletePeopleApiArg = {
  id: number;
};
export type GetApiServicesAppPeopleGetPeopleApiResponse =
  /** status 200 Success */ GetPeopleOutput;
export type GetApiServicesAppPeopleGetPeopleApiArg = {
  id?: number;
};
export type GetApiServicesAppPeopleGetPeoplesApiResponse =
  /** status 200 Success */ GetPeoplesOutput;
export type GetApiServicesAppPeopleGetPeoplesApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppPeopleUpsertPeopleApiResponse =
  /** status 200 Success */ UpsertPeopleOutput;
export type PostApiServicesAppPeopleUpsertPeopleApiArg = {
  upsertPeopleInput: UpsertPeopleInput;
};
export type DeleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfoApiResponse =
  /** status 200 Success */ DeletePeopleOutput;
export type DeleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfoApiArg = {
  id: number;
};
export type GetApiServicesAppPeopleExtraInfoGetPeopleExtraInfoApiResponse =
  /** status 200 Success */ GetPeopleExtraInfoOutput;
export type GetApiServicesAppPeopleExtraInfoGetPeopleExtraInfoApiArg = {
  id: number;
};
export type PostApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfoApiResponse =
  /** status 200 Success */ UpsertPeopleExtraInfoOutput;
export type PostApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfoApiArg = {
  upsertPeopleExtraInfoInput: UpsertPeopleExtraInfoInput;
};
export type DeleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentTypeApiResponse =
  /** status 200 Success */ DeletePlaceOfDeliveryPaymentTypeOutput;
export type DeleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentTypeApiArg =
  {
    id?: number;
  };
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypeApiResponse =
  /** status 200 Success */ GetPlaceOfDeliveryPaymentTypeOutput;
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypeApiArg =
  {
    id?: number;
  };
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypesApiResponse =
  /** status 200 Success */ GetPlaceOfDeliveryPaymentTypesOutput;
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypesApiArg =
  {
    placeOfDeliveryTreeId?: number;
    orderby?: string;
    isDescending?: boolean;
    pageNumber?: number;
    itemCount?: number;
    filter?: string;
  };
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentTypeApiResponse =
  /** status 200 Success */ UpsertPlaceOfDeliveryPaymentTypeOutput;
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentTypeApiArg =
  {
    upsertPlaceOfDeliveryPaymentTypeInput: UpsertPlaceOfDeliveryPaymentTypeInput;
  };
export type DeleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeFieldApiResponse =
  /** status 200 Success */ DeletePlaceOfDeliveryPaymentTypeFieldOutput;
export type DeleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeFieldApiArg =
  {
    id?: number;
  };
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldApiResponse =
  /** status 200 Success */ GetPlaceOfDeliveryPaymentTypeFieldOutput;
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldApiArg =
  {
    id?: number;
  };
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldsApiResponse =
  /** status 200 Success */ GetPlaceOfDeliveryPaymentTypeFieldsOutput;
export type GetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldsApiArg =
  {
    placeOfDeliveryPaymentTypeId?: number;
    orderby?: string;
    isDescending?: boolean;
    pageNumber?: number;
    itemCount?: number;
    filter?: string;
  };
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeFieldApiResponse =
  /** status 200 Success */ UpPlaceOfDeliveryPaymentTypeFieldOutput;
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeFieldApiArg =
  {
    upPlaceOfDeliveryPaymentTypeFieldInput: UpPlaceOfDeliveryPaymentTypeFieldInput;
  };
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeFieldApiResponse =
  /** status 200 Success */ UpsertPlaceOfDeliveryPaymentTypeFieldOutput;
export type PostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeFieldApiArg =
  {
    upsertPlaceOfDeliveryPaymentTypeFieldInput: UpsertPlaceOfDeliveryPaymentTypeFieldInput;
  };
export type DeleteApiServicesAppRoleDeleteRoleApiResponse =
  /** status 200 Success */ DeleteRoleOutput;
export type DeleteApiServicesAppRoleDeleteRoleApiArg = {
  id: number;
};
export type GetApiServicesAppRoleGetRoleApiResponse =
  /** status 200 Success */ GetRoleOutput;
export type GetApiServicesAppRoleGetRoleApiArg = {
  id: number;
};
export type GetApiServicesAppRoleGetRolesApiResponse =
  /** status 200 Success */ GetRolesOutput;
export type GetApiServicesAppRoleGetRolesApiArg = {
  name?: string;
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppRoleUpsertRoleApiResponse =
  /** status 200 Success */ UpsertRoleOutput;
export type PostApiServicesAppRoleUpsertRoleApiArg = {
  upsertRoleInput: UpsertRoleInput;
};
export type GetApiServicesAppSessionGetCurrentLoginInformationsApiResponse =
  /** status 200 Success */ GetCurrentLoginInformationsOutput;
export type GetApiServicesAppSessionGetCurrentLoginInformationsApiArg = void;
export type DeleteApiServicesAppTenantDeleteTenantApiResponse =
  /** status 200 Success */ DeleteTenantOutput;
export type DeleteApiServicesAppTenantDeleteTenantApiArg = {
  id: number;
};
export type GetApiServicesAppTenantGetTenantApiResponse =
  /** status 200 Success */ GetTenantOutput;
export type GetApiServicesAppTenantGetTenantApiArg = {
  id: number;
};
export type GetApiServicesAppTenantGetTenantsApiResponse =
  /** status 200 Success */ GetTenantsOutput;
export type GetApiServicesAppTenantGetTenantsApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppTenantUpsertTenantApiResponse =
  /** status 200 Success */ UpsertTenantOutput;
export type PostApiServicesAppTenantUpsertTenantApiArg = {
  upsertTenantInput: UpsertTenantInput;
};
export type PostApiTokenAuthAuthenticateApiResponse =
  /** status 200 Success */ AuthenticateResultModel;
export type PostApiTokenAuthAuthenticateApiArg = {
  authenticateModel: AuthenticateModel;
};
export type DeleteApiServicesAppTreeDeleteTreeApiResponse =
  /** status 200 Success */ DeleteTreeOutput;
export type DeleteApiServicesAppTreeDeleteTreeApiArg = {
  id: number;
};
export type GetApiServicesAppTreeGetTreeApiResponse =
  /** status 200 Success */ GetTreeOutput;
export type GetApiServicesAppTreeGetTreeApiArg = {
  id?: number;
};
export type GetApiServicesAppTreeGetTreesApiResponse =
  /** status 200 Success */ GetTreesOutput;
export type GetApiServicesAppTreeGetTreesApiArg = {
  treeType?: TreeType;
};
export type PostApiServicesAppTreeUpsertTreeApiResponse =
  /** status 200 Success */ UpsertTreeOutput;
export type PostApiServicesAppTreeUpsertTreeApiArg = {
  upsertTreeInput: UpsertTreeInput;
};
export type DeleteApiServicesAppUserDeleteUserApiResponse =
  /** status 200 Success */ DeleteUserOutput;
export type DeleteApiServicesAppUserDeleteUserApiArg = {
  id: number;
};
export type GetApiServicesAppUserGetUserApiResponse =
  /** status 200 Success */ GetUserOutput;
export type GetApiServicesAppUserGetUserApiArg = {
  id: number;
};
export type GetApiServicesAppUserGetUsersApiResponse =
  /** status 200 Success */ GetUsersOutput;
export type GetApiServicesAppUserGetUsersApiArg = {
  orderby?: string;
  isDescending?: boolean;
  pageNumber?: number;
  itemCount?: number;
  filter?: string;
};
export type PostApiServicesAppUserUpsertUserApiResponse =
  /** status 200 Success */ UpserUserOutput;
export type PostApiServicesAppUserUpsertUserApiArg = {
  upsertUserInput: UpsertUserInput;
};
export type IsTenantAvailableOutput = {
  state?: TenantAvailabilityState;
  tenantId?: number | null;
};
export type IsTenantAvailableInput = {
  tenancyName: string;
};
export type RegisterOutput = {
  canLogin?: boolean;
};
export type RegisterInput = {
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  password: string;
  captchaResponse?: string | null;
};
export type DeleteAccountNumberOutput = {
  result?: boolean;
};
export type AccountNumberDto = {
  id?: number;
  number?: string | null;
  shaba?: string | null;
  accountType?: AccountType;
  accountTypeName?: string | null;
  bank?: BankType;
  bankName?: string | null;
  totalDepositAmount?: number;
  expireDate?: string;
  active?: boolean;
  cardNumber?: string | null;
  minPriceForeach?: number;
  maxPriceForeach?: number;
  withdrawnFromExchange?: boolean;
  description?: string | null;
  isEspecial?: boolean;
  owner?: string | null;
  ownerNormalizedSearchName?: string | null;
  ownerNationalCode?: string | null;
  ownerBrithDate?: string | null;
  registrationNumber?: string | null;
  agentPeopleId?: number | null;
  agentPeopleFormalName?: string | null;
  buyRequestHeadId?: number | null;
  creationTime?: string;
  creatorName?: string | null;
};
export type Int32NameValue = {
  name?: string | null;
  value?: number;
};
export type GetAccountNumberOutput = {
  accountNumber?: AccountNumberDto;
  bankValues?: Int32NameValue[] | null;
  accountTypeValues?: Int32NameValue[] | null;
};
export type GetAccountNumbersOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  accountNumbers?: AccountNumberDto[] | null;
};
export type UpsertAccountNumberOutput = {
  result?: boolean;
};
export type UpsertAccountNumberInput = {
  id?: number;
  number?: string | null;
  shaba?: string | null;
  accountType?: AccountType;
  bank?: BankType;
  totalDepositAmount?: number;
  expireDate?: string;
  active?: boolean;
  cardNumber?: string | null;
  minPriceForeach?: number;
  maxPriceForeach?: number;
  withdrawnFromExchange?: boolean;
  description?: string | null;
  isEspecial?: boolean;
  owner?: string | null;
  ownerNormalizedSearchName?: string | null;
  ownerNationalCode?: string | null;
  ownerBrithDate?: string | null;
  registrationNumber?: string | null;
  agentPeopleId?: number | null;
  buyRequestHeadId?: number | null;
};
export type DeleteFileOutput = {
  result?: boolean;
};
export type FileDto = {
  file?: string | null;
  fileName?: string | null;
  fileType?: string | null;
};
export type FileDtoRead = {
  file?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileWithHeader?: string | null;
};
export type GetFileOutput = {
  file?: FileDto;
};
export type GetFileOutputRead = {
  file?: FileDtoRead;
};
export type UploadFileOutput = {
  result?: boolean;
};
export type UploadFileInput = {
  file?: FileDto;
  attachmentType?: AttachmentType;
  id?: number;
};
export type UploadFileInputRead = {
  file?: FileDtoRead;
  attachmentType?: AttachmentType;
  id?: number;
};
export type DeleteBaseTypeOutput = {
  result?: boolean;
};
export type BaseTypeDto = {
  id?: number;
  type?: BaseTypeEnum;
  name?: string | null;
  code?: string | null;
  creationTime?: string;
};
export type NameValue = {
  name?: string | null;
  value?: string | null;
};
export type GetBaseTypeOutput = {
  baseType?: BaseTypeDto;
  typeValues?: NameValue[] | null;
};
export type GetBaseTypesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  baseTypes?: BaseTypeDto[] | null;
};
export type UpsertBaseTypeOutput = {
  result?: boolean;
};
export type UpsertBaseTypeInput = {
  id?: number;
  type?: BaseTypeEnum;
  name: string;
  code?: string | null;
};
export type GetEnumsOutput = {};
export type GetEnumsOutputRead = {
  statusEnum?: NameValue[] | null;
};
export type ChatLabelDto = {
  label?: string | null;
  id?: number;
};
export type GetChatLabelsOutput = {
  labels?: ChatLabelDto[] | null;
};
export type AddLabelToChatInput = {
  number: string;
  label: string;
};
export type ChatDto = {
  id?: number;
  number: string;
  status: ChatStatusEnum;
};
export type CreateChatDto = {
  number: string;
  status: ChatStatusEnum;
};
export type ChatDtoPagedResultDto = {
  items?: ChatDto[] | null;
  totalCount?: number;
};
export type ChangeUiThemeInput = {
  theme: string;
};
export type DeleteCurrencyOutput = {
  result?: boolean;
};
export type CurrencyDto = {
  id?: number;
  name?: string | null;
  abbreviatedName?: string | null;
  creationTime?: string;
  status?: CurrencyStatus;
};
export type GetCurrenciesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  currencies?: CurrencyDto[] | null;
};
export type GetCurrencyOutput = {
  currency?: CurrencyDto;
  statusNameValues?: NameValue[] | null;
};
export type UpsertCurrencyOutput = {
  result?: boolean;
};
export type UpsertCurrencyInput = {
  id?: number;
  name: string;
  abbreviatedName: string;
  status?: CurrencyStatus;
};
export type DeleteCustomerGroupOutput = {
  result?: boolean;
};
export type CustomerGroupDto = {
  id?: number;
  name?: string | null;
  isSendEnable?: boolean;
  creationTime?: string;
};
export type GetCustomerGroupOutput = {
  customerGroup?: CustomerGroupDto;
};
export type GetCustomerGroupsOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  customerGroups?: CustomerGroupDto[] | null;
};
export type UpsertCustomerGroupOutput = {
  result?: boolean;
};
export type UpsertCustomerGroupInput = {
  id?: number;
  name?: string | null;
  isSendEnable?: boolean;
};
export type DeleteDocumentFieldOutput = {
  result?: boolean;
};
export type DocumentFieldDto = {
  id?: number;
  name?: string | null;
  inputType?: InputType;
  description?: string | null;
  possibleAnswers?: string | null;
  helpFileId?: string | null;
  creationTime?: string;
};
export type GetDocumentFieldOutput = {
  documentField?: DocumentFieldDto;
  inputTypeValues?: Int32NameValue[] | null;
};
export type GetDocumentFieldsOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  documentFields?: DocumentFieldDto[] | null;
};
export type UpsertDocumentFieldOutput = {
  result?: boolean;
};
export type UpsertDocumentFieldInput = {
  id?: number;
  name: string;
  inputType?: InputType;
  description?: string | null;
  possibleAnswers?: string[] | null;
};
export type ChangeExchangeRateOutput = {
  result?: boolean;
};
export type ChangeExchangeRateInput = {
  currencyId: number;
  customerGroupId: number;
  placeOfDeliveryPaymentTypeId: number;
  price: number;
  maxDiscount: number;
};
export type ExchangeRateDto = {
  currencyId?: number;
  price?: number;
  maxDiscount?: number;
  customerGroupId?: number;
  placeOfDeliveryPaymentTypeId?: number;
  creationTime?: string;
};
export type GetExchangeRatesOutput = {
  exchangeRates?: ExchangeRateDto[] | null;
};
export type EditTitleInput = {
  title: string;
  id: number;
};
export type EditAnswerInput = {
  answer: string;
  id: number;
};
export type FrequentlyAnswerDto = {
  id?: number;
  title?: string | null;
  answer?: string | null;
};
export type GetFrequentlyAnswersOutput = {
  data?: FrequentlyAnswerDto[] | null;
};
export type CreateFrequentlyAnswerInput = {
  title: string;
  answer: string;
};
export type DeleteFundOutput = {
  result?: boolean;
};
export type FundDto = {
  id?: number;
  name?: string | null;
  active?: boolean;
  cashierPersonId?: number;
  cashierPersonFormalName?: string | null;
  creationTime?: string;
};
export type GetFundOutput = {
  fund?: FundDto;
};
export type GetFundsOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  funds?: FundDto[] | null;
};
export type UpsertFundOutput = {
  result?: boolean;
};
export type UpsertFundInput = {
  id?: number;
  name: string;
  active?: boolean;
  cashierPersonId?: number;
};
export type FundPlaceDto = {
  id?: number;
  fundId?: number;
  fundName?: string | null;
  fundPeopleFormalName?: string | null;
  placeOfFundTreeId?: number;
  placeOfFundName?: string | null;
};
export type GetFundForPlaceOutput = {
  fundPlace?: FundPlaceDto;
};
export type SetFundForPlaceOutput = {
  result?: boolean;
};
export type SetFundForPlaceInput = {
  fundId: number;
  placeOfFundTreeId: number;
};
export type MessageSenderDto = {
  username?: string | null;
  userId?: number;
  messageId?: string | null;
};
export type GetMessagesByMessageIdsOutput = {
  data?: MessageSenderDto[] | null;
};
export type GetMessagesByMessageIdsInput = {
  messageIds?: string[] | null;
};
export type MessageDto = {
  id?: number;
  messageId: string;
};
export type CreateMessageDto = {
  messageId: string;
};
export type MessageDtoPagedResultDto = {
  items?: MessageDto[] | null;
  totalCount?: number;
};
export type DeleteNoteOutput = {
  result?: boolean;
};
export type NoteDto = {
  id?: number;
  itemId?: string | null;
  itemType?: NoteItemType;
  itemTypeName?: string | null;
  creationTime?: string;
  creationTimeTiks?: number;
  creatorUserName?: string | null;
  text?: string | null;
};
export type GetNoteOutput = {
  note?: NoteDto;
  itemTypeValues?: Int32NameValue[] | null;
};
export type GetNotesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  notes?: NoteDto[] | null;
};
export type UpsertNoteOutput = {
  result?: boolean;
};
export type UpsertNoteInput = {
  id?: number;
  text?: string | null;
  itemId?: string | null;
  itemType?: NoteItemType;
};
export type DeletePeopleOutput = {
  result?: boolean;
};
export type PeopleDto = {
  id?: number;
  personType?: PersonType;
  personTypeName?: string | null;
  formalName?: string | null;
  normalizedName?: string | null;
  active?: boolean;
  description?: string | null;
  fullLatinName?: string | null;
  normalizeSearchName?: string | null;
  name?: string | null;
  family?: string | null;
  title?: GenderType;
  titleName?: string | null;
  post?: string | null;
  educationStatus?: EducationStatus;
  educationStatusName?: string | null;
  citizenship?: CitizenshipType;
  citizenshipName?: string | null;
  legalPersonType?: LegalPersonType;
  legalPersonTypeName?: string | null;
  foreignExchangeType?: ForeignExchangeType;
  foreignExchangeTypeName?: string | null;
  registrationNumber?: string | null;
  brithday?: string | null;
  tenantId?: number | null;
  managerPeopleId?: number | null;
  managerPeopleFormalName?: string | null;
  ceoPeopleId?: number | null;
  ceoPeopleFormalName?: string | null;
  accountantPeopleId?: number | null;
  accountantPeopleFormalName?: string | null;
  introducingPeopleId?: number | null;
  introducingPeopleFormalName?: string | null;
  businessManagerPeopleId?: number | null;
  businessManagerPeopleFormalName?: string | null;
  financialManagerPeopleId?: number | null;
  financialManagerPeopleFormalName?: string | null;
  blongToPeopleId?: number | null;
  blongToPeopleFormalName?: string | null;
  industryId?: number | null;
  industryName?: string | null;
  groupId?: number | null;
  groupName?: string | null;
  creationTime?: string;
  nationalCardFileId?: string | null;
  profileImageFileId?: string | null;
  signFileId?: string | null;
  archiveFileInPersonFileId?: string | null;
  instructionFileId?: string | null;
  introductionFileId?: string | null;
};
export type GetPeopleOutput = {
  people?: PeopleDto;
  titleNameValues?: Int32NameValue[] | null;
  citizenshipTypeValues?: Int32NameValue[] | null;
  educationStatusValues?: Int32NameValue[] | null;
  legalPersonTypeValues?: Int32NameValue[] | null;
  personTypeValues?: Int32NameValue[] | null;
  foreignExchangeTypeValues?: Int32NameValue[] | null;
};
export type GetPeoplesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  peoples?: PeopleDto[] | null;
};
export type UpsertPeopleOutput = {
  result?: boolean;
};
export type UpsertPeopleInput = {
  id?: number;
  personType?: PersonType;
  active?: boolean;
  description?: string | null;
  fullLatinName?: string | null;
  name: string;
  family: string;
  title?: GenderType;
  post?: string | null;
  educationStatus?: EducationStatus;
  citizenship?: CitizenshipType;
  legalPersonType?: LegalPersonType;
  foreignExchangeType?: ForeignExchangeType;
  registrationNumber?: string | null;
  brithday?: string | null;
  managerPeopleId?: number | null;
  ceoPeopleId?: number | null;
  accountantPeopleId?: number | null;
  introducingPeopleId?: number | null;
  businessManagerPeopleId?: number | null;
  financialManagerPeopleId?: number | null;
  blongToPeopleId?: number | null;
  industryId?: number | null;
  groupId?: number | null;
};
export type PeopleExtraInfoDto = {
  id?: number;
  melliCode?: string | null;
  mobile?: string | null;
  tel1?: string | null;
  officeTel?: string | null;
  email?: string | null;
  address?: string | null;
  address2?: string | null;
  website?: string | null;
  fax?: string | null;
  postalCode?: string | null;
  tenantId?: number | null;
  latGeoLocation?: number | null;
  lonGeoLocation?: number | null;
  tel2?: string | null;
  mobile2?: string | null;
  accountingNumber?: string | null;
  whatsAppGroupId?: string | null;
  locationCityTreeId?: number | null;
  cityName?: string | null;
  municipalityTreeId?: number | null;
  municipalityName?: string | null;
};
export type CitiesAndShahrdariesDto = {
  city?: Int32NameValue;
  shahrdaries?: Int32NameValue[] | null;
};
export type GetPeopleExtraInfoOutput = {
  peopleExtraInfo?: PeopleExtraInfoDto;
  citiesAndShahrdaries?: CitiesAndShahrdariesDto[] | null;
};
export type UpsertPeopleExtraInfoOutput = {
  result?: boolean;
};
export type UpsertPeopleExtraInfoInput = {
  id: number;
  melliCode?: string | null;
  mobile?: string | null;
  tel1?: string | null;
  officeTel?: string | null;
  email?: string | null;
  address?: string | null;
  address2?: string | null;
  website?: string | null;
  fax?: string | null;
  postalCode?: string | null;
  tenantId?: number | null;
  latGeoLocation?: number | null;
  lonGeoLocation?: number | null;
  tel2?: string | null;
  mobile2?: string | null;
  accountingNumber?: string | null;
  whatsAppGroupId?: string | null;
  locationCityTreeId?: number | null;
  municipalityTreeId?: number | null;
};
export type DeletePlaceOfDeliveryPaymentTypeOutput = {
  result?: boolean;
};
export type PlaceOfDeliveryPaymentTypeDto = {
  id?: number;
  paymentType?: PaymentType;
  paymentTypeName?: string | null;
  active?: boolean;
  placeOfDeliveryTreeId?: number;
  placeOfDeliveryTreeName?: string | null;
  creationTime?: string;
};
export type GetPlaceOfDeliveryPaymentTypeOutput = {
  placeOfDeliveryPaymentType?: PlaceOfDeliveryPaymentTypeDto;
  paymentTypeValues?: Int32NameValue[] | null;
};
export type GetPlaceOfDeliveryPaymentTypesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  placeOfDeliveryPaymentTypes?: PlaceOfDeliveryPaymentTypeDto[] | null;
};
export type UpsertPlaceOfDeliveryPaymentTypeOutput = {
  result?: boolean;
};
export type UpsertPlaceOfDeliveryPaymentTypeInput = {
  id?: number;
  paymentType?: PaymentType;
  active?: boolean;
  placeOfDeliveryTreeId?: number;
};
export type DeletePlaceOfDeliveryPaymentTypeFieldOutput = {
  result?: boolean;
};
export type PlaceOfDeliveryPaymentTypeFieldDto = {
  id?: number;
  isRequired?: boolean;
  isActive?: boolean;
  order?: number;
  group?: string | null;
  documentFieldId?: number;
  documentFieldName?: string | null;
  placeOfDeliveryPaymentTypeId?: number;
  creationTime?: string;
};
export type GetPlaceOfDeliveryPaymentTypeFieldOutput = {
  placeOfDeliveryPaymentTypeField?: PlaceOfDeliveryPaymentTypeFieldDto;
};
export type GetPlaceOfDeliveryPaymentTypeFieldsOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  placeOfDeliveryPaymentTypeFields?:
    | PlaceOfDeliveryPaymentTypeFieldDto[]
    | null;
};
export type UpPlaceOfDeliveryPaymentTypeFieldOutput = {
  result?: boolean;
};
export type UpPlaceOfDeliveryPaymentTypeFieldInput = {
  id?: number;
};
export type UpsertPlaceOfDeliveryPaymentTypeFieldOutput = {
  result?: boolean;
};
export type UpsertPlaceOfDeliveryPaymentTypeFieldInput = {
  id?: number;
  isRequired?: boolean;
  isActive?: boolean;
  group?: string | null;
  placeOfDeliveryPaymentTypeId?: number;
  documentFieldId?: number;
};
export type DeleteRoleOutput = {
  result?: boolean;
};
export type NameValueDto = {
  name?: string | null;
  value?: string | null;
};
export type RoleDto = {
  id?: number;
  name: string;
  description?: string | null;
  displayName?: string | null;
  grantedPermissions?: string[] | null;
};
export type GetRoleOutput = {
  allPermissions?: NameValueDto[] | null;
  role?: RoleDto;
};
export type GetRolesOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  roles?: RoleDto[] | null;
};
export type UpsertRoleOutput = {
  result?: boolean;
};
export type UpsertRoleInput = {
  id?: number;
  name: string;
  description?: string | null;
  grantedPermissions?: string[] | null;
};
export type ApplicationInfoDto = {
  version?: string | null;
  releaseDate?: string;
  features?: {
    [key: string]: boolean | null;
  } | null;
};
export type UserLoginInfoDto = {
  id?: number;
  name?: string | null;
  surname?: string | null;
  userName?: string | null;
  emailAddress?: string | null;
};
export type TenantLoginInfoDto = {
  id?: number;
  tenancyName?: string | null;
  name?: string | null;
};
export type GetCurrentLoginInformationsOutput = {
  application?: ApplicationInfoDto;
  user?: UserLoginInfoDto;
  tenant?: TenantLoginInfoDto;
};
export type DeleteTenantOutput = {
  result?: boolean;
};
export type TenantDto = {
  id?: number;
  tenancyName: string;
  name: string;
  isActive?: boolean;
  isDeleted?: boolean;
  creationTime?: string | null;
};
export type GetTenantOutput = {
  tenant?: TenantDto;
};
export type GetTenantsOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  tenants?: TenantDto[] | null;
};
export type UpsertTenantOutput = {
  result?: boolean;
};
export type UpsertTenantInput = {
  id?: number;
  tenancyName: string;
  name: string;
  isActive?: boolean;
};
export type AuthenticateResultModel = {
  accessToken?: string | null;
  encryptedAccessToken?: string | null;
  expireInSeconds?: number;
  userId?: number;
};
export type AuthenticateModel = {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient?: boolean;
};
export type DeleteTreeOutput = {
  result?: boolean;
};
export type TreeDto = {
  id?: number;
  name?: string | null;
  lName?: string | null;
  parentTreeId?: number | null;
  treeType?: TreeType;
  hierarchyParentId?: string | null;
  level?: TreeLevel;
  parentNames?: string | null;
  parentNameWithoutFirstLevel?: string | null;
  creationTime?: string;
};
export type Int32NameValueDto = {
  name?: string | null;
  value?: number;
};
export type GetTreeOutput = {
  tree?: TreeDto;
  treeTypeValues?: Int32NameValueDto[] | null;
  treeLevelValues?: Int32NameValueDto[] | null;
};
export type GetTreesOutput = {
  trees?: TreeDto[] | null;
};
export type UpsertTreeOutput = {
  result?: boolean;
};
export type UpsertTreeInput = {
  id?: number;
  name: string;
  lName: string;
  parentTreeId?: number | null;
  treeType?: TreeType;
};
export type DeleteUserOutput = {
  result?: boolean;
};
export type UserDto = {
  id?: number;
  userName: string;
  name: string;
  surname?: string | null;
  emailAddress: string;
  phoneNumber?: string | null;
  roleNames?: string[] | null;
  isActive?: boolean;
  creationTime?: string;
  isPhoneNumberConfirmed?: boolean;
};
export type GetUserOutput = {
  user?: UserDto;
  roles?: string[] | null;
};
export type GetUsersOutput = {
  currentPage?: number;
  resultCount?: number;
  totalPage?: number;
  users?: UserDto[] | null;
};
export type UpserUserOutput = {
  result?: boolean;
};
export type UpsertUserInput = {
  id?: number;
  phoneNumber: string;
  password?: string | null;
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  roleNames: string[];
};
export enum TenantAvailabilityState {
  $1 = 1,
  $2 = 2,
  $3 = 3,
}
export enum AccountType {
  $1 = 1,
  $2 = 2,
}
export enum BankType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $9 = 9,
  $10 = 10,
  $11 = 11,
  $12 = 12,
  $13 = 13,
  $14 = 14,
  $15 = 15,
  $16 = 16,
  $17 = 17,
  $18 = 18,
  $19 = 19,
  $20 = 20,
  $21 = 21,
  $22 = 22,
  $23 = 23,
  $24 = 24,
  $25 = 25,
  $26 = 26,
  $27 = 27,
  $28 = 28,
  $29 = 29,
  $30 = 30,
  $31 = 31,
  $32 = 32,
  $33 = 33,
  $34 = 34,
}
export enum AttachmentType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $9 = 9,
  $10 = 10,
  $11 = 11,
  $12 = 12,
  $13 = 13,
  $14 = 14,
  $15 = 15,
  $16 = 16,
  $17 = 17,
  $18 = 18,
  $19 = 19,
  $20 = 20,
}
export enum BaseTypeEnum {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
}
export enum ChatStatusEnum {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $9 = 9,
  $10 = 10,
  $11 = 11,
  $12 = 12,
  $13 = 13,
}
export enum CurrencyStatus {
  $1 = 1,
  $2 = 2,
}
export enum InputType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
}
export enum NoteItemType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
}
export enum PersonType {
  $1 = 1,
  $2 = 2,
}
export enum GenderType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
}
export enum EducationStatus {
  $1 = 1,
  $2 = 2,
  $3 = 3,
}
export enum CitizenshipType {
  $1 = 1,
  $2 = 2,
}
export enum LegalPersonType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
}
export enum ForeignExchangeType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
}
export enum PaymentType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
}
export enum TreeType {
  $1 = 1,
}
export enum TreeLevel {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
}
export const {
  usePostApiServicesAppAccountIsTenantAvailableMutation,
  usePostApiServicesAppAccountRegisterMutation,
  useDeleteApiServicesAppAccountNumberDeleteAccountNumberMutation,
  useGetApiServicesAppAccountNumberGetAccountNumberQuery,
  useGetApiServicesAppAccountNumberGetAccountNumbersQuery,
  usePostApiServicesAppAccountNumberUpsertAccountNumberMutation,
  useDeleteApiServicesAppAttachmentFileDeleteFileMutation,
  useGetApiServicesAppAttachmentFileGetFileQuery,
  usePostApiServicesAppAttachmentFileUploadFileMutation,
  useDeleteApiServicesAppBaseTypeDeleteBaseTypeMutation,
  useGetApiServicesAppBaseTypeGetBaseTypeQuery,
  useGetApiServicesAppBaseTypeGetBaseTypesQuery,
  usePostApiServicesAppBaseTypeUpsertBaseTypeMutation,
  useGetApiServicesAppChatGetEnumsQuery,
  useGetApiServicesAppChatGetChatLabelsQuery,
  useDeleteApiServicesAppChatDeleteLabelMutation,
  usePostApiServicesAppChatAddLabelToChatMutation,
  usePostApiServicesAppChatCreateMutation,
  usePutApiServicesAppChatUpdateMutation,
  useGetApiServicesAppChatGetAllQuery,
  useGetApiServicesAppChatGetQuery,
  useDeleteApiServicesAppChatDeleteMutation,
  usePostApiServicesAppConfigurationChangeUiThemeMutation,
  useDeleteApiServicesAppCurrencyDeleteCurrencyMutation,
  useGetApiServicesAppCurrencyGetCurrenciesQuery,
  useGetApiServicesAppCurrencyGetCurrencyQuery,
  usePostApiServicesAppCurrencyUpsertCurrencyMutation,
  useDeleteApiServicesAppCustomerGroupDeleteCustomerGroupMutation,
  useGetApiServicesAppCustomerGroupGetCustomerGroupQuery,
  useGetApiServicesAppCustomerGroupGetCustomerGroupsQuery,
  usePostApiServicesAppCustomerGroupUpsertCustomerGroupMutation,
  useDeleteApiServicesAppDocumentFieldDeleteDocumentFieldMutation,
  useGetApiServicesAppDocumentFieldGetDocumentFieldQuery,
  useGetApiServicesAppDocumentFieldGetDocumentFieldsQuery,
  usePostApiServicesAppDocumentFieldUpsertDocumentFieldMutation,
  usePostApiServicesAppExchangeRateChangeExchangeRateMutation,
  useGetApiServicesAppExchangeRateGetExchangeRatesQuery,
  usePostApiServicesAppFrequentlyAnswerEditTitleMutation,
  usePostApiServicesAppFrequentlyAnswerEditAnswerMutation,
  useGetApiServicesAppFrequentlyAnswerGetFrequentlyAnswersQuery,
  usePostApiServicesAppFrequentlyAnswerCreateFrequentlyAnswerMutation,
  useDeleteApiServicesAppFrequentlyAnswerDeleteFrequentlyAnswerMutation,
  useDeleteApiServicesAppFundDeleteFundMutation,
  useGetApiServicesAppFundGetFundQuery,
  useGetApiServicesAppFundGetFundsQuery,
  usePostApiServicesAppFundUpsertFundMutation,
  useGetApiServicesAppFundPlaceGetFundForPlaceQuery,
  usePostApiServicesAppFundPlaceSetFundForPlaceMutation,
  usePostApiServicesAppMessagePullMessagesByMessageIdsMutation,
  usePostApiServicesAppMessageCreateMutation,
  useDeleteApiServicesAppMessageDeleteMutation,
  useGetApiServicesAppMessageGetQuery,
  useGetApiServicesAppMessageGetAllQuery,
  usePutApiServicesAppMessageUpdateMutation,
  useDeleteApiServicesAppNoteDeleteNoteMutation,
  useGetApiServicesAppNoteGetNoteQuery,
  useGetApiServicesAppNoteGetNotesQuery,
  usePostApiServicesAppNoteUpsertNoteMutation,
  useDeleteApiServicesAppPeopleDeletePeopleMutation,
  useGetApiServicesAppPeopleGetPeopleQuery,
  useGetApiServicesAppPeopleGetPeoplesQuery,
  usePostApiServicesAppPeopleUpsertPeopleMutation,
  useDeleteApiServicesAppPeopleExtraInfoDeletePeopleExtraInfoMutation,
  useGetApiServicesAppPeopleExtraInfoGetPeopleExtraInfoQuery,
  usePostApiServicesAppPeopleExtraInfoUpsertPeopoleExtraInfoMutation,
  useDeleteApiServicesAppPlaceOfDeliveryPaymentTypeDeletePlaceOfDeliveryPaymentTypeMutation,
  useGetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypeQuery,
  useGetApiServicesAppPlaceOfDeliveryPaymentTypeGetPlaceOfDeliveryPaymentTypesQuery,
  usePostApiServicesAppPlaceOfDeliveryPaymentTypeUpsertPlaceOfDeliveryPaymentTypeMutation,
  useDeleteApiServicesAppPlaceOfDeliveryPaymentTypeFieldDeletePlaceOfDeliveryPaymentTypeFieldMutation,
  useGetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldQuery,
  useGetApiServicesAppPlaceOfDeliveryPaymentTypeFieldGetPlaceOfDeliveryPaymentTypeFieldsQuery,
  usePostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpPlaceOfDeliveryPaymentTypeFieldMutation,
  usePostApiServicesAppPlaceOfDeliveryPaymentTypeFieldUpsertPlaceOfDeliveryPaymentTypeFieldMutation,
  useDeleteApiServicesAppRoleDeleteRoleMutation,
  useGetApiServicesAppRoleGetRoleQuery,
  useGetApiServicesAppRoleGetRolesQuery,
  usePostApiServicesAppRoleUpsertRoleMutation,
  useGetApiServicesAppSessionGetCurrentLoginInformationsQuery,
  useDeleteApiServicesAppTenantDeleteTenantMutation,
  useGetApiServicesAppTenantGetTenantQuery,
  useGetApiServicesAppTenantGetTenantsQuery,
  usePostApiServicesAppTenantUpsertTenantMutation,
  usePostApiTokenAuthAuthenticateMutation,
  useDeleteApiServicesAppTreeDeleteTreeMutation,
  useGetApiServicesAppTreeGetTreeQuery,
  useGetApiServicesAppTreeGetTreesQuery,
  usePostApiServicesAppTreeUpsertTreeMutation,
  useDeleteApiServicesAppUserDeleteUserMutation,
  useGetApiServicesAppUserGetUserQuery,
  useGetApiServicesAppUserGetUsersQuery,
  usePostApiServicesAppUserUpsertUserMutation,
} = injectedRtkApi;
